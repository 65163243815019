<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
            <el-row type="flex" :gutter="4" style="width: 100%;">
                <el-col :span="24" style="text-align: left;">
                    <el-button size="small" class="ms-10" type="danger">
                        <el-icon style="margin-right: 5px;">
                            <download />
                        </el-icon>
                        <download-excel :data="categories" :name="'product_categories.xlsx'">
                        {{ $filters.capitalize($t('action.download'))}} {{ $t('title.categories')}}
                        </download-excel>
                    </el-button>
                    <el-button size="small" class="ms-10" type="warning">
                        <download-excel :data="template_products" :name="'product_template.xlsx'">
                        {{ $filters.capitalize($t('action.download'))}} {{ $t('title.format')}} {{ $t('menu.product')}}
                        <el-icon >
                            <download />
                        </el-icon>
                        </download-excel>
                    </el-button>
                </el-col>
            </el-row>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-upload
                ref="upload"
                class="upload-demo"
                :limit="1"
                :on-change="changeFile"
                :before-upload="beforeUpload"
                auto-upload="false"
                accept="application/vnd.ms-excel, application/msexcel, application/x-msexcel, application/x-ms-excel, application/x-excel, application/x-dos_ms_excel, application/xls, application/x-xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
            <template #trigger>
                <el-button type="primary" size="small" class="ms-10" >
                    <el-icon style="margin-right: 5px;">
                        <upload />
                    </el-icon>
                    {{ $filters.capitalize($t('action.select-file')) }}
                </el-button>
            </template>
            </el-upload>
        </el-row>
        <el-form-item style="max-width: 600px" label="Shop" >
                <el-select v-model="form.shop_id">
                <el-option
                    v-for="(item, index) in shops"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" >
                <el-table :data="form.products" style="overflow-x: scroll;" :row-class-name="tableRowClassName">
                    <el-table-column label="#" width="50">
                        <template #default="scope">
                            {{ scope.$index }}
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.name'))" width="100">
                        <template #default="scope">
                            <el-input v-model="scope.row.name" size="small" step="1" min="1" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.category'))" width="200">
                        <template #default="scope">
                            <el-select v-model="scope.row.product_category_ids" multiple>
                                <el-option
                                    v-for="item in categories"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                />
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('menu.provider'))" width="200">
                        <template #default="scope">
                            <el-select v-model="scope.row.provider_id">
                                <el-option
                                    v-for="item in providers"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                />
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.keywords'))" width="200">
                        <template #default="scope">
                            <el-input v-model="scope.row.keywords" size="small" step="1" min="1" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.meta-description'))" width="200">
                        <template #default="scope">
                            <el-input v-model="scope.row.meta_description" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.description'))" width="600">
                        <template #default="scope">
                            <ckeditor :editor="editor" :id="'ck-description-'+scope.$index"  v-model="scope.row.description" :config="editorConfig"></ckeditor>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.features'))" width="600">
                        <template #default="scope">
                            <el-tag v-for="item in scope.row.features" type="success" v-bind:key="item">{{ $item }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('barcode'))" width="150">
                        <template #default="scope">
                            <el-input v-model="scope.row.barcode" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('sku'))" width="150">
                        <template #default="scope">
                            <el-input v-model="scope.row.sku" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('product.import-price'))" width="150">
                        <template #default="scope">
                            <el-input-number step="1" min="0" v-model="scope.row.import_price" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('product.selling-price'))" width="150">
                        <template #default="scope">
                            <el-input-number step="1" min="0" v-model="scope.row.selling_price" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('product.show-price'))" width="150">
                        <template #default="scope">
                            <el-input-number step="1" min="0" v-model="scope.row.show_price" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.length'))" width="150">
                        <template #default="scope">
                            <el-input-number step="1" min="0" v-model="scope.row.length" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.width'))" width="150">
                        <template #default="scope">
                            <el-input-number step="1" min="0" v-model="scope.row.width" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.height'))" width="150">
                        <template #default="scope">
                            <el-input-number step="1" min="0" v-model="scope.row.height" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.weight'))" width="150">
                        <template #default="scope">
                            <el-input-number step="1" min="0" v-model="scope.row.weight" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.ship-type'))" width="150">
                        <template #default="scope">
                                <el-select v-model="scope.row.type_id">
                                    <el-option
                                        v-for="item in productShipTypes"
                                        :key="item.val"
                                        :label="item.label"
                                        :value="item.val"
                                    />
                             </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.status'))" width="100">
                        <template #default="scope">
                                <el-select v-model="scope.row.status">
                                    <el-option
                                        v-for="item in statusList"
                                        :key="item.val"
                                        :label="item.label"
                                        :value="item.val"
                                    />
                             </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.is-public'))" width="100">
                        <template #default="scope">
                                <el-select v-model="scope.row.is_public">
                                    <el-option
                                        v-for="item in publicList"
                                        :key="item.val"
                                        :label="item.label"
                                        :value="item.val"
                                    />
                             </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.operators'))" width="100">
                        <template #default="scope">
                            <el-button size="small" type="danger" @click="removeProduct(scope.$index)" :icon="Delete">{{ $filters.capitalize($t('action.delete')) }}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">{{ $filters.capitalize($t('action.save')) }}</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                <router-link :to="{name: 'ProductIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>
import {
  Select,
  Back,
  Refresh,
  Download,
  Upload
} from '@element-plus/icons-vue'
</script>
<script>
import { list as listShops } from '@/api/shop';
import {list as listCategory} from '@/api/product_category';
import {list as listProvider} from '@/api/provider';
import { importProduct } from '@/api/product';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import readXlsxFile from 'read-excel-file';
import { product_template } from '@/constants/templates';
import { template_products } from '@/templates/products';
import { ElButton  } from 'element-plus';
// import { product_feature_image, product_image, distance_tolerance } from '@/constants/configs';
export default {
    name: "ProductImport",
    components: {
        ElButton
    },
    data(){
        return {
            dialogVisible: false,
            form: {
                products: [],
                shop_id: 0
            },
            statusList: [
                {
                    val: 0,
                    label: 'no-active'
                },
                {
                    val: 1,
                    label: 'active'
                }
            ],
            publicList: [
                {
                    val: 0,
                    label: 'no-public'
                },
                {
                    val: 1,
                    label: 'public'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            shops: [],
            categories: [],
            providers: [],
            template_products: template_products,
            productShipTypes: [
                {
                    val: 0,
                    label: 'normal'
                },
                {
                    val: 1,
                    label: 'frozen'
                },
                {
                    val: 2,
                    label: 'fragile'
                },
                {
                    val: 3,
                    label: 'danger'
                },
                {
                    val: 4,
                    label: 'ex-danger'
                },
                {
                    val: 5,
                    label: 'other'
                },
            ],
        };
    },
    created(){
        this.getShops();
        this.getCategory();
        this.getProviders();
    },
    methods: {
        async getShops(){
            let res = await listShops('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.shops = res.data.data;
            }
        },
        async getProviders(){
            let res = await listProvider('');
            console.log(res);
            if (res != null && res.status == 200 && res.data != null) {
                this.providers = res.data.data;
            }
        },
        async getCategory(){
            let res = await listCategory('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.categories = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.products.length <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('menu.product'),
                    type: 'error',
                });
                return;
            }
            if (this.form.shop_id <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('menu.shop'),
                    type: 'error',
                });
                return;
            }
            for (let i = 0; i < this.form.products.length; i++) {
                if (this.form.products[i].provider_id == null || this.form.products[i].provider_id <= 0) {
                    ElMessage({
                        message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('menu.provider'),
                        type: 'error',
                    });
                    return;
                }
            }
            
            let formData = new FormData();
            formData.append('shop_id', this.form.shop_id);
            for(let i = 0; i < this.form.products.length; i++) {
                formData.append('products['+i+'][name]', this.form.products[i].name);
                formData.append('products['+i+'][keywords]', this.form.products[i].keywords);
                formData.append('products['+i+'][meta_description]', this.form.products[i].meta_description);
                formData.append('products['+i+'][description]', this.form.products[i].description);
                formData.append('products['+i+'][barcode]', this.form.products[i].barcode);
                formData.append('products['+i+'][sku]', this.form.products[i].sku);
                formData.append('products['+i+'][show_price]', this.form.products[i].show_price);
                formData.append('products['+i+'][import_price]', this.form.products[i].import_price);
                formData.append('products['+i+'][selling_price]', this.form.products[i].selling_price);
                formData.append('products['+i+'][length]', this.form.products[i].length);
                formData.append('products['+i+'][width]', this.form.products[i].width);
                formData.append('products['+i+'][height]', this.form.products[i].height);
                formData.append('products['+i+'][weight]', this.form.products[i].weight);
                formData.append('products['+i+'][type_id]', this.form.products[i].type_id);
                formData.append('products['+i+'][status]', this.form.products[i].status);
                formData.append('products['+i+'][is_public]', this.form.products[i].is_public);
                formData.append('products['+i+'][provider_id]', this.form.products[i].provider_id);
                for(let k = 0; k < this.form.products[i].features.length; k++){
                formData.append('products['+i+'][features][]', this.form.products[i].features[k]);
                }
                for(let j = 0; j < this.form.products[i].product_category_ids.length; j++) {
                    formData.append('products['+i+'][product_category_ids][]', Number(this.form.products[i].product_category_ids[j]));
                }

            }
            this.$store.state.loading = true;
            let res = await importProduct(formData);
            this.$store.state.loading = false;
            console.log(res);
            if (res != null && res.status == 200) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    type: 'success',
                });
                this.$router.push({ path: '/product' });
            } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-failure')),
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.form = {
                product: [],
                shop_id: 0
            };
        },
        async changeFile(e){
            if(e.raw == null || (e.raw.type.indexOf('application/vnd.ms-excel') !== 0 
            && e.raw.type.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') !== 0
            && e.raw.type.indexOf('application/msexcel') !== 0
            && e.raw.type.indexOf('application/x-msexcel') !== 0
            && e.raw.type.indexOf('application/x-ms-excel') !== 0
            && e.raw.type.indexOf('application/x-excel') !== 0
            && e.raw.type.indexOf('application/x-dos_ms_excel') !== 0
            && e.raw.type.indexOf('application/xls') !== 0
            && e.raw.type.indexOf('application/x-xls') !== 0
            )) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.wrong-format')),
                    type: 'error',
                });
                return;
            }
            let data = await readXlsxFile(e.raw, { sheet: 'Sheet1', schema: product_template });
            if (data.errors == null || data.errors.length == 0) {
                this.form.products = data.rows;
                console.log(this.form.products);
            }
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        },
        tableRowClassName(row){
            if (row.rowIndex % 2 == 1) {
                return 'warning-row';
            } else 
                return 'success-row';
        },
        removeProduct(index) {
            this.form.products.splice(index, 1);
        },
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
<style>
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-9);
}
.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
</style>
<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" style="width: 100%;" label-width="auto" label-position="top">
            <el-form-item :label="$filters.capitalize($t('title.keywords'))" style="max-width: 600px">
                <el-input v-model="form.keywords" placeholder="Keywords"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.status'))">
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="$filters.capitalize($t('action.'+item.label))"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">{{ $filters.capitalize($t('action.save')) }}</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                <router-link :to="{name: 'SearchEngineIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
    </el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
} from '@element-plus/icons-vue'
</script>
<script>
import { edit, detail } from '@/api/search_engine';
import { ElMessage } from 'element-plus';
export default {
    name: "SearchEngineEdit",
    data(){
        return {
            form: {
                keywords: '',
                status: 0
            },
            statusList: [
                {
                    val: 0,
                    label: 'created'
                },
                {
                    val: 1,
                    label: 'processing'
                },
                {
                    val: 2,
                    label: 'finished'
                },
                {
                    val: 3,
                    label: 'recreated'
                }
            ],
        };
    },
    created(){
        this.getDetail();
    },
    methods: {
        async getDetail(){
            this.$store.state.loading = true;
            let res = await detail(this.$route.params.id);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.form = res.data;
            }
        },
        async submitForm(){
            this.$store.state.loading = true;
            let formData = new FormData();
            formData.append('status', this.form.status);
            let res = await edit(this.$route.params.id, formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    type: 'success',
                });
                this.$router.push({ path: '/search-engine' });
            } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-failure')),
                    type: 'error',
                });
            }
        },
    }
}
</script>
<style>
#ck-description {
    width: auto !important;
}
</style>
<template>
 
  <el-row type="flex" :gutter="4" style="width: 100%; padding-left: 10px;">
    <el-col :xl="8" :lg="8" :sm="24" :xs="24" :md="12">
      <el-card class="box-card" >
        <template #header>
          <div class="card-header">
            <el-icon :size="24" color="#e6a23c"><user-filled /></el-icon>
            <span>{{ $filters.capitalize($t('dashboard.customers')) }}</span>
            <router-link :to="{name: 'CustomerIndex'}" style="margin-left: 10px;">
              <el-button size="small" class="ms-10" type="success" :icon="View">{{ $filters.capitalize($t('action.detail')) }}</el-button>
            </router-link>
          </div>
        </template>
        <el-row type="flex" :gutter="4" style="width: 100%;">
          <el-col :span="20" style="text-align: left;">
            {{ $filters.capitalize($t('dashboard.total-customers')) }}:
          </el-col>
          <el-col :span="4">{{ customers }}</el-col>
        </el-row>
      </el-card>
    </el-col>
    <el-col :xl="8" :lg="8" :sm="24" :xs="24" :md="12">
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <el-icon :size="24" color="#e6a23c"><shop /></el-icon>
            <span>{{ $filters.capitalize($t('dashboard.shopes')) }}</span>
            <router-link :to="{name: 'ShopIndex'}" style="margin-left: 10px;">
              <el-button size="small" class="ms-10" type="success" :icon="View">{{ $filters.capitalize($t('action.detail')) }}</el-button>
            </router-link>
          </div>
        </template>
        <el-row type="flex" :gutter="4" style="width: 100%;">
          <el-col :span="20" style="text-align: left;">
            {{ $filters.capitalize($t('dashboard.total-shopes')) }}:
          </el-col>
          <el-col :span="4">{{ shops }}</el-col>
        </el-row>
      </el-card>
    </el-col>
    <el-col :xl="8" :lg="8" :sm="24" :xs="24" :md="12">
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <el-icon :size="24" color="#e6a23c"><box /></el-icon>
            <span>{{ $filters.capitalize($t('dashboard.products')) }}</span>
            <router-link :to="{name: 'ProductIndex'}" style="margin-left: 10px;">
              <el-button size="small" class="ms-10" type="success" :icon="View">{{ $filters.capitalize($t('action.detail')) }}</el-button>
            </router-link>
          </div>
        </template>
        <el-row type="flex" :gutter="4" style="width: 100%;">
          <el-col :span="20" style="text-align: left;">
            {{ $filters.capitalize($t('dashboard.total-products')) }}:
          </el-col>
          <el-col :span="4">{{ products }}</el-col>
        </el-row>
      </el-card>
    </el-col>
</el-row>
<el-row type="flex" :gutter="4" style="width: 100%; padding-left: 10px;">
  <el-col :xl="8" :lg="8" :sm="24" :xs="24" :md="12">
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <el-icon :size="24" color="#e6a23c"><shopping-cart /></el-icon>
            <span>{{ $filters.capitalize($t('dashboard.orders')) }}</span>
            <router-link :to="{name: 'OrderIndex'}" style="margin-left: 10px;">
              <el-button size="small" class="ms-10" type="success" :icon="View">{{ $filters.capitalize($t('action.detail')) }}</el-button>
            </router-link>
          </div>
        </template>
        <el-row type="flex" :gutter="4" style="width: 100%;">
          <el-col :span="20" style="text-align: left;">
            {{ $filters.capitalize(this.$t('dashboard.total-orders')) }}:
          </el-col>
          <el-col :span="4">{{ orders }}</el-col>
        </el-row>
      </el-card>
    </el-col>
    <el-col :xl="8" :lg="8" :sm="24" :xs="24" :md="12">
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <el-icon :size="24" color="#e6a23c"><memo /></el-icon>
            <span>{{ $filters.capitalize($t('dashboard.articles')) }}<th></th></span>
            <router-link :to="{name: 'NewsIndex'}" style="margin-left: 10px;">
              <el-button size="small" class="ms-10" type="success" :icon="View">{{ $filters.capitalize($t('action.detail')) }}</el-button>
            </router-link>
          </div>
        </template>
        <el-row type="flex" :gutter="4" style="width: 100%;">
          <el-col :span="20" style="text-align: left;">
            {{ $filters.capitalize($t('dashboard.total-articles')) }}:
          </el-col>
          <el-col :span="4">{{ news }}</el-col>
        </el-row>
      </el-card>
    </el-col>
</el-row>
</template>
<script setup>
import {
    ShoppingCart,
    View,
    Box,
    UserFilled,
    Shop,
    Memo
  } from '@element-plus/icons-vue'
</script>
<script>
import {detail} from '@/api/dashboard'
export default {
    name: "DashboardPage",
    components: {
    },
    data(){
      return {
        customers: 0,
        shops: 0,
        products: 0,
        orders: 0,
        news: 0
      };
    },
    created(){
      this.getDashboard();
    },
    beforeMount(){
      this.$store.state.hideSidebar = false;
        this.$store.state.hideHeader = false;
        this.$store.state.hideBreadScumb = false;
        this.$store.state.hideFooter = false;
    },
    methods:{
      async getDashboard(){
        let res = await detail();
        if (res != null && res.status == 200) {
          this.customers = res.data.customers;
          this.shops = res.data.shops;
          this.products = res.data.products;
          this.orders = res.data.orders;
          this.news = res.data.news;
        }
      }
    }
};
</script>
<style>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}
</style>
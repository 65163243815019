<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
        <el-text class="mx-1" type="danger">{{ $filters.capitalize($t('title.image-have-size')) + news_feature_image.width +"x"+news_feature_image.height+"px" }}</el-text>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                    auto-upload="false"
                    accept="image/jpg, image/jpeg, image/png"
                >

                <img v-if="form.feature_image" :src="form.feature_image" class="avatar" style="max-width: 600px;" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.category'))" >
                <el-select
                    v-model="form.news_category_ids"
                    multiple
                    placeholder="Select"
                    style="width: 240px"
                    >
                    <el-option
                        v-for="item in categories"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('article.tags'))" >
                <el-select
                    v-model="form.tags"
                    multiple
                    placeholder="Tags"
                    style="width: 240px"
                    allow-create="true"
                    filterable="true"
                    >
                    <el-option
                        v-for="item in tags"
                        :key="item"
                        :label="item"
                        :value="item"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.name'))" >
                <el-input v-model="form.name" placeholder="name" />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.keywords'))" >
                <el-input v-model="form.keywords" placeholder="Keywords" />
            </el-form-item>
            <el-form-item style="max-width: 600px"  :label="$filters.capitalize($t('title.meta-description'))" >
                <el-input type="textarea" v-model="form.meta_description" placeholder="" />
            </el-form-item>
            <el-form-item :label="$filters.capitalize($t('title.description'))" style="max-width: 600px">
            </el-form-item>
            <ckeditor :editor="editor" id="ck-description"  v-model="form.description" :config="editorConfig"></ckeditor>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.status'))" >
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="$filters.capitalize($t('title.'+item.label))"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('article.public-time'))" >
                <el-date-picker @change="changePublicTime" v-model="form.public_time" type="datetime" format="YYYY-MM-DD HH:mm:ss" />
            </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">{{ $filters.capitalize($t('action.save')) }}</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                <router-link :to="{name: 'NewsIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus
} from '@element-plus/icons-vue'
</script>
<script>
import {list as listCategory} from '@/api/news_category';
import { detail, edit } from '@/api/news';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {news_feature_image} from '@/constants/configs';
export default {
    name: "NewsEdit",
    data(){
        return {
            form: {
                name: '',
                feature_image: '',
                slug: '',
                description: '',
                keywords: '',
                meta_description: '',
                image: null,
                status: 0,
                user_id: 0,
                news_category_ids: [],
                tags: [],
                public_time: (new Date())
            },
            statusList: [
                {
                    val: 0,
                    label: 'no-active'
                },
                {
                    val: 1,
                    label: 'active'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            categories: [],
            tags: []
        };
    },
    created(){
        this.getDetail();
        this.getCategory();
    },
    methods: {
        async getDetail(){
            this.$store.state.loading = true;
            let res = await detail(this.$route.params.id);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.form = res.data;
                if (res.data.tags != null && res.data.tags != '') {
                    this.form.tags = JSON.parse(res.data.tags);
                    this.tags = this.form.tags;
                }
                this.form.status = this.form.status ? 1 : 0;
                this.form.feature_image = this.form.feature_image != null && this.form.feature_image != '' ? process.env.VUE_APP_BASE_HOST + this.form.feature_image : null;
                if (this.form.public_time != null) {
                    this.form.public_time = new Date(this.form.public_time*1000);
                }
                this.form.news_category_ids = [];
                if (res.data.news_news_categories != null && res.data.news_news_categories.length > 0) {
                    for (let i = 0; i < res.data.news_news_categories.length; i++) {
                        this.form.news_category_ids.push(res.data.news_news_categories[i].news_category_id);
                    }
                }
                
            }
        },
        async getCategory(){
            let res = await listCategory('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.categories = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.name'),
                    type: 'error',
                });
                return;
            }
            if (this.form.keywords.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.keywords'),
                    type: 'error',
                });
                return;
            }
            if (this.form.description.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.description'),
                    type: 'error',
                });
                return;
            }
            if (this.form.meta_description.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.meta-description'),
                    type: 'error',
                });
                return;
            }
            if (this.form.news_category_ids.length <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.category'),
                    type: 'error',
                });
                return;
            }
            if (this.form.public_time.getTime() < (new Date()).getTime()) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('article.public-time')) + " >= " + this.$t('article.now'),
                    type: 'error',
                });
                return;
            }
            
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('description', this.form.description);
            formData.append('keywords', this.form.keywords);
            formData.append('meta_description', this.form.meta_description);
            formData.append('public_time', this.form.public_time.getTime()/1000);
            // if (this.form.tags.length > 0) {
            //     console.log(this.form.tags);
            //     return;
            // }
            for(var i = 0; i < this.form.tags.length; i++){
                formData.append('tags[]', this.form.tags[i]);
            }
            for(var j = 0; j < this.form.news_category_ids.length; j++) {
                formData.append('news_category_ids[]', Number(this.form.news_category_ids[j]));
            }
            if (this.form.image != null) {
                formData.append('feature_image', this.form.image);
            }
            // if (formData != null) {
            //     console.log(formData);
            //     return;
            // }
            formData.append('status', this.form.status);
            this.$store.state.loading = true;
            let res = await edit(this.$route.params.id, formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    type: 'success',
                });
                this.$router.push({ path: '/news' });
            } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-failure')),
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.getDetail();
            this.form.image = null;
        },
        async changeFile(e){
            if(e.raw == null || e.raw.type.indexOf('image/') !== 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.wrong-format')),
                    type: 'error',
                });
                return;
            }
            const isLt2M = e.raw.size / 1024 / 1024 < news_feature_image.size;
            if (!isLt2M) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.file-less-or-equal'))+ news_feature_image.size + 'MB',
                    type: 'error',
                });
                return;
            }
            var tmppath = URL.createObjectURL(e.raw);
            const img = new Image();
            img.src = tmppath;
            // await img.decode();
            // if (Math.abs(img.width - news_feature_image.width) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-width-equal'))+ news_feature_image.width + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            // if (Math.abs(img.height - news_feature_image.height) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-height-equal'))+ news_feature_image.height + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            this.form.image = e.raw;
            this.form.feature_image = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        },
        changePublicTime(e) {
            let t = new Date(e);
            if (t.getTime() < (new Date()).getTime()) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('article.public-time')) + " >= " + this.$t('article.now'),
                    type: 'error',
                });
                return;
            }
        }
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
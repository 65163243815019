<template>
  <el-form ref="form" :model="form" class="form-inline" :inline="true">
    <el-divider content-position="left">{{ $filters.capitalize($t('action.search')) }}</el-divider>
    <el-row type="flex" :gutter="1" >
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <el-form-item>
          <el-input v-model="form.keywords" placeholder="Từ khóa" @change="changeKeyword"  />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <el-form-item>
        <el-date-picker v-model="form.from_date" type="date" placeholder="2024-01-11" @change="changeFromDate" />
      </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <el-form-item>
        <el-date-picker v-model="form.to_date" type="date" placeholder="2024-01-30" @change="changeToDate" />
      </el-form-item>
      </el-col>
      <el-col ::xs="14" :sm="14" :md="10" :lg="10" :xl="10" type="flex" justify="space-between" class="group-buttons">
        <el-button size="small" type="primary" @click="onSearch" :icon="Search">{{ $filters.capitalize($t('action.search')) }}</el-button>
        <el-button size="small" @click="onRefresh" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
        <el-button size="small" v-if="canexport" type="success" @click="onExportData">{{ $filters.capitalize($t('action.export')) }}</el-button>
        <router-link :to="{name: 'ProviderAdd'}">
                <el-button size="small" class="ms-10" type="success" :icon="Plus">{{ $filters.capitalize($t('action.add')) }}</el-button>
            </router-link>
        <router-link :to="{name: 'ProviderImport'}">
            <el-button size="small" class="ms-10" type="success" :icon="Upload">{{ $filters.capitalize($t('action.import')) }}</el-button>
        </router-link>
      </el-col>
    </el-row>
  </el-form>
</template>
<script setup>

import {
Search,
Plus,
Refresh,
Upload
} from '@element-plus/icons-vue'
</script>

<script>
import { RoleNames } from '@/constants/role';
export default {
name: "SearchForm",
props: {
  changeKeyword: {
    type: Function,
    default(val){
      console.log(val);
    }
  },
  changeFromDate:{
    type: Function,
    default(val){
      console.log(val);
    }
  },
  changeToDate:{
    type: Function,
    default(val){
      console.log(val);
    }
  },
  onSearch:{
    type: Function,
    default(val){
      console.log(val);
    }
  },
  onRefresh:{
    type: Function,
    default(val){
      console.log(val);
    }
  },
  onExportData:{
    type: Function,
    default(val){
      console.log(val);
    }
  },
  canexport: {
    type: Boolean,
    default(){
      return false;
    }
  }
},
data() {
    return {
        form: {
          keywords: '',
          from_date: '',
          to_date: '',
          role: ''
        },
        roles: RoleNames
    };
}
};
</script>
<style>
.el-row {
margin-bottom: 20px;
padding-bottom: 20px;
}
.el-row:last-child {
margin-bottom: 0;
}
.el-col {
border-radius: 4px;
}
.grid-content {
border-radius: 4px;
min-height: 36px;
}
.form-inline {
padding: 0px 20px;
}
.form-inline .el-row {
padding: 0px 20px;
border-bottom: 1px solid var(--el-border-color);
}
.form-inline .el-input {
--el-input-width: 100%;
}

.form-inline .el-select {
--el-select-width: 220px;
}
.line{
text-align: center;
}
@media screen and (max-width: 768px) {
.group-buttons button {
margin-bottom: 10px;
margin-left: 0px !important;
}
}
@media screen and (min-width: 961px) {
.group-buttons button {
margin-bottom: 10px;
margin-left: 10px;
}
}
</style>

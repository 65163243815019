<template>
    <search-form 
    :change-keyword="changeKeywords" 
    :change-from-date="changeFromDate" 
    :change-to-date="changeToDate" 
    :change-shop="changeShop"
    :change-product="changeProduct"
    :on-search="onSearch"
    :on-refresh="onRefresh"
    :on-export-data="onExport"
    :products="products"
    :shops="shops"
    />
    <el-row type="flex" :gutter="4" style="width: 100%; padding-left: 10px; margin-top: 20px;">
      <el-col :lg="8" :xl="8" :sm="24" :md="24" :xs="24">
        <el-card class="box-card" >
          <template #header>
            <div class="card-header">
              <span>Nhập kho</span>
            </div>
          </template>
          <Chart
          :size="{ width: 500, height: 400 }"
          :data="imports"
          :margin="margin"
          >
          <template #layers>
            <Grid strokeDasharray="2,2" />
            <Bar :data-keys="['name', 'total_qty']" :barStyle="{ fill: '#90e0ef' }" :maxWidth="20" />
          </template>
        </Chart>
        </el-card>
      </el-col>
      <el-col :lg="8" :xl="8" :sm="24" :md="24" :xs="24">
        <el-card class="box-card" >
          <template #header>
            <div class="card-header">
              <span>Xuất kho</span>
            </div>
          </template>
          <Chart
          :size="{ width: 500, height: 400 }"
          :data="exports"
          :margin="margin"
          >
          <template #layers>
            <Grid strokeDasharray="2,2" />
            <Bar :data-keys="['name', 'total_qty']" :barStyle="{ fill: '#0096c7' }" :maxWidth="20" />
          </template>
        </Chart>
        </el-card>
      </el-col>
      <el-col :lg="8" :xl="8" :sm="24" :md="24" :xs="24">
        <el-card class="box-card" >
          <template #header>
            <div class="card-header">
              <span>Chi phí</span>
            </div>
          </template>
          <Chart
          :size="{ width: 500, height: 400 }"
          :data="costs"
          :margin="margin"
          :direction="direction">
          <template #layers>
            <Grid strokeDasharray="2,2" />
            <Bar :data-keys="['name', 'amount']" :barStyle="{ fill: '#e76f51' }" :maxWidth="20" />
          </template>
        </Chart>
        </el-card>
      </el-col>
    </el-row>
  
  </template>
  <script setup>
  import { Chart, Grid, Bar } from 'vue3-charts'
</script>
  <script>
  
  import { list } from '@/api/statistic';
  import { list as listShops } from '@/api/shop';
  import { list as listProducts } from '@/api/product';
  import SearchForm from './components/SearchForm.vue';
  export default {
      name: "StatisticPage",
      components: {
    SearchForm,
},
      data(){
        return {
          delete_id: 0,
          admins: [],
          imports: [],
          exports: [],
          costs: [],
          search: {
            keywords: '',
            from_date: 0,
            to_date: 0,
            shop_ids: [],
            product_ids: [],
            total: 0,
            page: 1,
            pagesize: 5,
          },
          show: true,
          pagesizes: [10,20,50,100],
          products: [],
          shops: [],
          margin: {
            left: 0,
            top: 10,
            right: 0,
            bottom: 10
          }
        }
      },
      beforeMount(){
        this.$store.state.hideSidebar = false;
        this.$store.state.hideHeader = false;
        this.$store.state.hideBreadScumb = false;
        this.$store.state.hideFooter = false;
      },
      created(){
        var params = this.$router.currentRoute.value.fullPath.split('?');
        var query = '';
        if (params.length > 1) {
          query = params.slice(-1);
        }
        this.getLists(query);
        var currentQuery = this.$router.currentRoute.value.query;
        if (currentQuery.keywords != undefined) {
          this.search.keywords = currentQuery.keywords;
        }
        if (currentQuery.user_ids != undefined) {
          this.search.user_ids = currentQuery.user_ids;
        }
        if (currentQuery.product_ids != undefined) {
          this.search.product_ids = currentQuery.product_ids;
        }
        if (currentQuery.from_date != undefined) {
          this.search.from_date = currentQuery.from_date;
        }
        if (currentQuery.to_date != undefined) {
          this.search.to_date = currentQuery.to_date;
        }
        if (currentQuery.page != undefined) {
          this.search.page = currentQuery.page;
        }
        if (currentQuery.pagesize != undefined) {
          this.search.pagesize = currentQuery.pagesize;
        }
        this.getProducts();
        this.getShops();
      },
      methods: {
        async getProducts(){
          this.$store.state.loading = true;
          let res = await listProducts('pagesize=1000');
          this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.products = res.data.data;
            }
        },
        async getShops(){
          this.$store.state.loading = true;
          let res = await listShops('pagesize=1000');
          this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.shops = res.data.data;
            }
        },
        async getLists(query){
          this.$store.state.loading = true;
          let res = await list(query);
          this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                let imports = [];
                for(let i = 0; i < res.data.data.imports.length; i++) {
                  imports.push({
                    product_id: res.data.data.imports[i].product_id,
                    name: res.data.data.imports[i].product.name,
                    total_qty: res.data.data.imports[i].total_qty,
                  });
                }
                this.imports = imports;
                let exports = [];
                for(let i = 0; i < res.data.data.exports.length; i++) {
                  exports.push({
                    product_id: res.data.data.exports[i].product_id,
                    name: res.data.data.exports[i].product.name,
                    total_qty: res.data.data.exports[i].total_qty,
                  });
                }
                this.exports = exports;
                this.costs = res.data.data.costs;
            }
        },
        changePage(value){
            if (this.search.page != value) {
                this.search.page = value;
                this.$router.push({ path: '/statistic', query: this.search });
            }
        },
        nextPage(){
            this.search.page = Number(this.search.page)+1;
            this.$router.push({ path: '/statistic', query: this.search });
        },
        prevPage(){
            this.search.page = Number(this.search.page)-1;
            this.$router.push({ path: '/statistic', query: this.search });
        },
        async onSearch(){
          if (this.search.keywords.trim() != '' 
          || this.search.role > 0 
          || this.search.from_date > 0 
          || this.search.to_date > 0
          || this.search.shop_ids.length > 0
          || this.search.product_ids.length > 0) {
              this.$router.push({ path: '/statistic', query: this.search });
          }
        },
        async onRefresh(){
          this.search.keywords = '';
          this.search.role = 0;
          this.search.from_date = 0;
          this.search.to_date = 0;
          this.$router.push({ path: '/statistic' });
        },
        async onExport(){

        },
        changeKeywords(e) {
            this.search.keywords = e;
        },
        changeFromDate(e) {
          let from_date = new Date(e);
          if (from_date != null) {
            this.search.from_date = from_date.getTime()/1000;
          }
        },
        changeToDate(e) {
          let to_date = new Date(e);
          if (to_date != null) {
            this.search.to_date = to_date.getTime()/1000;
          }
        },
        changeShop(e) {
          this.search.shop_ids = e;
        },
        
        handleSizeChange(val){
          this.search.pagesize = Number(val);
          this.$router.push({ path: '/statistic', query: this.search });
        },
        changeProduct(val){
          console.log(val);
          this.search.product_ids = val;
        }
      },
      watch: {
        '$route'(to, from){
            console.log(from);
            var params = to.fullPath.split('?');
            var query = '';
            if (params.length > 1) {
                query = params.slice(-1)[0];
                query = query.replaceAll('product_ids=', 'product_ids[]=');
                query = query.replaceAll('shop_ids=', 'shop_ids[]=');
            }
            this.getLists(query);
        }
    },
  };
  </script>
  <style scoped>
  .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  .item {
    margin-top: 10px;
    margin-right: 0px;
  }
  </style>
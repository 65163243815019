import {login, getCsrf, logout} from '@/api/login';
import { getToken, setToken, removeToken, getUser, setUser, removeUser } from '@/utils/authentication'
const getDefaultState = () => {
    return {
      token: getToken(),
      user: getUser()
    }
};
const state = getDefaultState();
const mutations = {
    RESET_STATE: (state) => {
      Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, user) => {
        state.user = user
    },
};
const actions = {
    login({ commit }, userForm) {
        const { email, password } = userForm;
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append('email', email);
            formData.append('password', password);
            login(formData).then(response => {
              console.log(response);
              const { data } = response;
              const { csrf_token } = data;
              commit('SET_TOKEN', csrf_token);
              setToken(csrf_token);
              const csrfEl = document.querySelector('head meta[name="csrf-token"]');
              csrfEl.setAttribute('content', csrf_token);
              return resolve();
            }).catch(error => {
              return reject(error);
            })
          })
    },
    initCsrf({commit}) {
      return new Promise((resolve, reject) => {
        getCsrf().then(response => {
          console.log(response);
          const { data } = response;
          const { csrf } = data;
          commit('SET_TOKEN', csrf);
          setToken(csrf);
          const csrfEl = document.querySelector('head meta[name="csrf-token"]');
          csrfEl.setAttribute('content', csrf);
          return resolve();
        }).catch(error => {
          return reject(error);
        })
      })
    },
    // user logout
    logout({ commit }) {
        return new Promise((resolve, reject) => {
          console.log(reject);
          logout().then(() => {
            removeToken() // must remove  token  first
            removeUser()
            commit('RESET_STATE')
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },
    resetToken({ commit }) {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            removeUser()
            commit('RESET_STATE')
            resolve()
        })
    },
    setTokenServer({commit}, token){
        setToken(token);
        commit('SET_TOKEN', token);
      },
    setUserServer({commit}, user){
        setUser(user);
        commit('SET_USER', user);
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
  };
<template>
    <el-row class="row" :gutter="10" style="height: 100%;">
      <el-col :span="20" class="navbar-title">
        <div class="header-title">
          <span class="text-large font-600 mr-3">{{ $t('title.header-nav') }}</span>
        </div>
      </el-col>
      <el-col :span="2" class="navbar-profile">
        <div class="toolbar">
          <el-dropdown>
            <el-icon style="margin-right: 8px;" color="white" size="16"><user /></el-icon>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item><el-icon><user /></el-icon>{{ $filters.capitalize($t('menu.profile')) }}</el-dropdown-item>
                <el-dropdown-item @click="onLogout"><el-icon><lock /></el-icon>{{ $filters.capitalize($t('menu.logout')) }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <span>{{ user.name }}</span>
        </div>
      </el-col>
      <el-col :span="2" class="navbar-language">
        <div class="toolbar">
          <el-select placeholder="Language" v-model="locale" class="el-select-language">
            <template v-slot:prefix>
              {{ lang == 'en' ? '🇬🇧&emsp;' : '🇻🇳&emsp;'}}
            </template>
            <el-option
              v-for="optionLocale in supportLocales"
              :key="`locale-${optionLocale}`"
              :value="optionLocale">
              {{ optionLocale == 'en' ? '🇬🇧&emsp;' : '🇻🇳&emsp;'}} {{ $filters.capitalize(optionLocale) }}
            </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
  </template>
<script setup>

import {
  User,
  Lock
} from '@element-plus/icons-vue'
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import {SUPPORT_LOCALES as supportLocales, setI18nLanguage } from '../i18n';
const { locale } = useI18n({ useScope: 'global' });
let lang = locale.value;
watch(locale, (val) => {
  setI18nLanguage(val);
  lang = locale.value;
});
</script>
<script>
export default {
    name: "HeaderNav",
    methods: {
      async onLogout(){
        this.$store.dispatch('user/logout');
        this.$router.push({ path: '/login' });
      },
      getRoute() {
        const routeArr = this.$route.path.split("/");
        return routeArr[1];
      },
    },
    data(){
      return {
        user: this.$store.getters.user.user,
        locale: true
      }
    },
    watch: {
      '$store.state.user.user': {
        handler(newUser){
            this.user = newUser;
        },
        immediate: true,
        deep: true
      },
    }
}
</script>
<style scoped>
.toolbar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: 20px;
}
.header-title {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: 20px;
}
.el-header {
  position: relative;
  background-color: #545c64;
  color: white;
}
.el-header .el-row, .el-header .el-col {
  height: 100%;
  padding-bottom: 0px;
}
@media screen and (max-width: 480px) {
  .navbar-title {
    max-width: 50% !important;
  }
  .navbar-profile {
    max-width: 25% !important;
  }
  .navbar-language {
    max-width: 25% !important;
  }
}
/* .el-select-language::before {
  content: url('http://localhost:8080/vi.png');
} */
</style>
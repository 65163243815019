const api_backend = 'backend';
const shop_image = {
    width: 660,
    height: 660,
    size: 1
};
const shop_barcode_payment = {
    width: 660,
    height: 660,
    size: 1
};
const shop_banner_image = {
    width: 1320,
    height: 160,
    size: 1
};
const product_feature_image = {
    width: 660,
    height: 660,
    size: 1
};
const product_image = {
    width: 660,
    height: 660,
    size: 2
};
const news_feature_image = {
    width: 660,
    height: 660,
    size: 1
};
const news_image = {
    width: 660,
    height: 660,
    size: 1
};
const provider_feature_image = {
    width: 660,
    height: 660,
    size: 1
};
const avatar_image = {
    width: 660,
    height: 660,
    size: 1
};
const costtype_image = {
    width: 660,
    height: 660,
    size: 1
}
const cost_bill_image = {
    width: 600,
    height: 600,
    size: 1
}
const short_video = {
    width: 660,
    height: 660,
    size: 50
}
const distance_tolerance = 10;
export {api_backend, shop_image, shop_barcode_payment, product_feature_image, product_image, news_feature_image, news_image, provider_feature_image, avatar_image, costtype_image, cost_bill_image, distance_tolerance, short_video, shop_banner_image};
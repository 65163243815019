const template_products = [
    {
        INDEX: 1,
        PROVIDER_ID: 1,
        NAME: 'Sản phẩm 1',
        BARCODE: 'barcode1',
        SKU: 'sku1',
        KEYWORDS: 'Sản phẩm 1',
        META_DESCRIPTION: 'Mô tả meta sản phẩm 1',
        DESCRIPTION: 'Mô tả sản phẩm 1',
        FEATURES: 'đặc tính 1; đặc tính 2; đặc tính 3; đặc tính 4',
        PRODUCT_CATEGORY_IDS: '1;2;3',
        IMPORT_PRICE: 100000,
        SELLING_PRICE: 150000,
        SHOW_PRICE: 170000,
        STATUS: 1,
        IS_PUBLIC: 1,
        LENGTH: 0,
        WIDTH: 0,
        HEIGHT: 0,
        WEIGHT: 0,
        TYPE_ID: 0
    },
    {
        INDEX: 2,
        PROVIDER_ID: 2,
        NAME: 'Sản phẩm 2',
        BARCODE: 'barcode2',
        SKU: 'sku2',
        KEYWORDS: 'Sản phẩm 2',
        META_DESCRIPTION: 'Mô tả meta sản phẩm 2',
        DESCRIPTION: 'Mô tả sản phẩm 2',
        FEATURES: 'đặc tính 1; đặc tính 2; đặc tính 3; đặc tính 4',
        PRODUCT_CATEGORY_IDS: '2;3;4',
        IMPORT_PRICE: 100000,
        SELLING_PRICE: 150000,
        SHOW_PRICE: 170000,
        STATUS: 1,
        IS_PUBLIC: 1,
        LENGTH: 0,
        WIDTH: 0,
        HEIGHT: 0,
        WEIGHT: 0,
        TYPE_ID: 0
    }
];
  export {template_products};
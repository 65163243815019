<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
            <el-form-item style="max-width: 600px" label="Shop">
                <el-input v-model="form.shop.name" placeholder="notes" readonly  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('export.order-code'))">
                <el-input v-model="form.order.code" placeholder="" />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.notes'))">
                <el-input v-model="form.notes" placeholder="notes" />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('export.export-date'))">
                <el-date-picker v-model="form.export_date" type="datetime" format="YYYY-MM-DD HH:mm:ss"  />
            </el-form-item>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24">
                <el-table :data="form.products" style="width: 100%">
                    <el-table-column label="#" width="50">
                        <template #default="scope">
                            {{ scope.$index }}
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('menu.product'))">
                        <template #default="scope">
                            <el-select v-model="scope.row.id">
                                <el-option
                                    v-for="item in products"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                />
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.quantity'))">
                        <template #default="scope">
                            <el-input-number v-model="scope.row.qty" size="small" step="1" min="1" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.notes'))">
                        <template #default="scope">
                            <el-input v-model="scope.row.notes" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.operators'))" width="100">
                        <template #default="scope">
                            <el-button size="small" type="danger" @click="removeProduct(scope.$index)" :icon="Delete">{{ $filters.capitalize($t('action.delete')) }}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="addProduct" :icon="Plus">{{ $filters.capitalize($t('action.add')) }}</el-button>
            </el-col>
        </el-row>
            <el-form-item style="max-width: 600px" label="Trạng thái">
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="$filters.capitalize($t('title.'+item.label))"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">{{ $filters.capitalize($t('action.save')) }}</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                <router-link :to="{name: 'ExportStockIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                </router-link>
            </el-col>
        </el-row>
    </el-form>
    </el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus,
  Delete
} from '@element-plus/icons-vue'
</script>
<script>
import { detail, edit } from '@/api/export_stock';
import {list as listProducts} from '@/api/product';
import { ElMessage } from 'element-plus';
export default {
    name: "ExportStockAdd",
    data(){
        return {
            form: {
                shop_id: 0,
                products: [],
                notes: '',
                export_date: (new Date()),
                status: 1,
                order_id: 0,
                shop: {},
                order: {}
            },
            statusList: [
                {
                    val: 0,
                    label: 'no-active'
                },
                {
                    val: 1,
                    label: 'active'
                }
            ],
            shops: [],
            products: [],
            orders: []
        };
    },
    created(){
        this.getDetail();
    },
    methods: {
        async getDetail() {
            let res = await detail(this.$route.params.id);
            if (res != null && res.status == 200 && res.data != null) {
                this.form = res.data;
                if (this.form.shop_id > 0) {
                    this.getProducts(this.form.shop_id);
                }
                if (this.form.export_stock_details.length > 0) {
                    this.form.products = [];
                    for(let i = 0; i < this.form.export_stock_details.length; i++){
                        this.form.products.push({
                            id: this.form.export_stock_details[i].product_id,
                            qty: this.form.export_stock_details[i].qty,
                            notes: this.form.export_stock_details[i].notes
                        });
                    }
                }
                if (this.form.export_date != null) {
                    this.form.export_date = new Date(this.form.export_date*1000);
                }
            }
        },
        async getProducts(shopId) {
            let res = await listProducts('page=1&pagesize=10000&shop_id='+shopId.toString());
            if (res != null && res.status == 200 && res.data != null) {
                this.products = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.shop_id == 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('menu.shop'),
                    type: 'error',
                });
                return;
            }
            if (this.form.products.length <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('menu.shop'),
                    type: 'error',
                });
                return;
            }
            let formData = new FormData();
            formData.append('notes', this.form.notes);
            formData.append('export_date', this.form.export_date.getTime()/1000);
            formData.append('status', this.form.status);
            if (this.form.products.length > 0) {
                for(let i = 0; i < this.form.products.length; i++) {
                    formData.append('products['+i+'][id]', Number(this.form.products[i].id));
                    formData.append('products['+i+'][qty]', Number(this.form.products[i].qty)); 
                    formData.append('products['+i+'][notes]', this.form.products[i].notes); 
                }
            }
            this.$store.state.loading = true;
            let res = await edit(this.$route.params.id, formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    type: 'success',
                });
                this.$router.push({ path: '/export-stock' });
            } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-failure')),
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.products = [];
            this.getDetail();
        },
        removeProduct(index) {
            this.form.products.splice(index, 1);
        },
        addProduct(){
            this.form.products.push({
                id: 0,
                qty: 1,
                notes: ''
            });
        },
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
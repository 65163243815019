<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
        <el-text class="mx-1" type="danger">{{ $filters.capitalize($t('title.image-have-size')) + provider_feature_image.width +"x"+provider_feature_image.height+"px" }}</el-text>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                    :auto-upload="false"
                    accept="image/jpg, image/jpeg, image/png"
                >

                <img v-if="form.logo" :src="form.logo" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon"><Plus />{{ $filters.capitalize($t('title.feature-image')) }}</el-icon>
                </el-upload>
            </el-col>
        </el-row>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.name'))" >
                <el-input v-model="form.name" placeholder="name" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" label="Slug" >
                <el-input v-model="form.slug" placeholder="slug" width="300px" readonly />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.owner'))" >
                <el-input v-model="form.owner.email" placeholder="slug" width="300px" readonly />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.phone'))" >
                <el-input v-model="form.phone" placeholder="phone" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.address'))" >
                <el-input type="text" v-model="form.address" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.website'))" >
                <el-input type="text" v-model="form.website" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" label="Facebook" >
                <el-input type="text" v-model="form.facebook" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" label="Tiktok" >
                <el-input type="text" v-model="form.tiktok" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" label="Zalo" >
                <el-input type="text" v-model="form.zalo" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" label="Twitter" >
                <el-input type="text" v-model="form.twitter" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" label="Instagram" >
                <el-input type="text" v-model="form.instagram" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item :label="$filters.capitalize($t('title.description'))" style="max-width: 600px">
            </el-form-item>
            <ckeditor :editor="editor" id="ck-description"  v-model="form.description" :config="editorConfig"></ckeditor>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.status'))" >
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="$filters.capitalize($t('title.'+item.label))"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">{{ $filters.capitalize($t('action.save')) }}</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                <router-link :to="{name: 'ProviderIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus
} from '@element-plus/icons-vue'
</script>
<script>
import { edit, detail } from '@/api/provider';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {provider_feature_image} from '@/constants/configs';
export default {
    name: "ProviderEdit",
    data(){
        return {
            form: {
                name: '',
                logo: '',
                slug: '',
                description: '',
                phone: '',
                address: '',
                image: null,
                status: 0,
                owner: {},
                zalo: '',
                facebook: '',
                twitter: '',
                tiktok: '',
                instagram: '',
                website: ''
            },
            statusList: [
                {
                    val: 0,
                    label: this.$filters.capitalize(this.$t('title.no-active'))
                },
                {
                    val: 1,
                    label: this.$filters.capitalize(this.$t('title.active'))
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            }
        };
    },
    created(){
        this.getDetail();
    },
    methods: {
        async getDetail(){
            this.$store.state.loading = true;
            let res = await detail(this.$route.params.id);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.form = res.data;
                if (this.form.owner == null) {
                    this.form.owner = {};
                }
                this.form.status = this.form.status ? 1 : 0;
                this.form.logo = this.form.logo != null && this.form.logo != '' ? process.env.VUE_APP_BASE_HOST + this.form.logo : null;
            }
        },
        async submitForm(){
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.name'),
                    type: 'error',
                });
                return;
            }
            if (this.form.description.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.description'),
                    type: 'error',
                });
                return;
            }
            this.$store.state.loading = true;
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('description', this.form.description);
            formData.append('phone', this.form.phone);
            formData.append('address', this.form.address);
            formData.append('facebook', this.form.facebook);
            formData.append('zalo', this.form.zalo);
            formData.append('twitter', this.form.twitter);
            formData.append('tiktok', this.form.tiktok);
            formData.append('instagram', this.form.instagram);
            formData.append('website', this.form.website);
            if (this.form.image != null) {
                formData.append('logo', this.form.image);
            }
            formData.append('status', this.form.status);
            let res = await edit(this.$route.params.id, formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    type: 'success',
                });
                this.$router.push({ path: '/provider' });
            } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-failure')),
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.getDetail();
            this.form.image = null;
        },
        async changeFile(e){
            if(e.raw == null || e.raw.type.indexOf('image/') !== 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.wrong-format')),
                    type: 'error',
                });
                return;
            }
            const isLt2M = e.raw.size / 1024 / 1024 < provider_feature_image.size;
            if (!isLt2M) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.file-less-or-equal'))+ provider_feature_image.size + 'MB',
                    type: 'error',
                });
                return;
            }
            var tmppath = URL.createObjectURL(e.raw);
            const img = new Image();
            img.src = tmppath;
            // await img.decode();
            // if (Math.abs(img.width - provider_feature_image.width) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-width-equal'))+ provider_feature_image.width + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            // if (Math.abs(img.height - provider_feature_image.height) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-height-equal'))+ provider_feature_image.height + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            this.form.image = e.raw;
            this.form.logo = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        }
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
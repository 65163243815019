import { createApp } from 'vue'
import App from './App.vue'
import store from "./store";
import router from './router'
import ElementPlus from 'element-plus'
import JsonExcel from "vue-json-excel3";
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import CKEditor from '@ckeditor/ckeditor5-vue';
import i18n from './i18n';

const app = createApp(App)
app.use(store);
app.use(i18n());
app.use(router)
app.use(ElementPlus)
app.use(CKEditor)
app.component("downloadExcel", JsonExcel);
app.config.globalProperties.$filters = {
    capitalize(value){
        if (!value) return value;
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
    },
    showStatus(value){
        if (value) return 'active';
        return 'no-active';
    },
    showSearchJobStatus(value){
        switch(value) {
            case 0:
                return 'created';
            case 1:
                return 'processing';
            case 2:
                return 'finished';
            case 3:
                return 'recreated';
            default:
                return 'deleted';
        }
    },
    showPublic(value) {
        if (value) return 'public';
        return 'no-public';
    },
    showTop(value) {
        if (value) return 'show';
        return 'not-show';
    }
};
app.mount('#app')

import request from '@/utils/request'

function provinces() {
    return request({
        url: '/ship/provinces',
        method: 'get',
      })
}
function districts(id) {
    return request({
        url: '/ship/districts?province_id='+id,
        method: 'get',
      })
}
function wards(id) {
    return request({
        url: '/ship/wards?district_id='+id,
        method: 'get',
      })
}
function services(data) {
    return request({
        url: '/ship/services',
        method: 'post',
        data: data
      })
}
function price(data) {
    return request({
        url: '/ship/price',
        method: 'post',
        data: data
      })
}
export {provinces, districts, wards, services, price};
<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
        <el-form ref="form" :model="form" label-width="auto" label-position="top">
            <el-text class="mx-1" type="danger">{{ $filters.capitalize($t('title.image-have-size')) + avatar_image.width +"x"+avatar_image.height+"px" }}</el-text>
            <el-row type="flex" :gutter="1" style="width: 100%;">
                <el-col :span="24" type="flex" style="justify-content: center !important;">
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :show-file-list="false"
                        :on-change="addFile"
                        :before-upload="beforeUpload"
                        auto-upload="false"
                        accept="image/jpg, image/jpeg, image/png"
                    >

                    <img v-if="form.avatar" :src="form.avatar" class="avatar" style="max-width: 600px;" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                    </el-upload>
                </el-col>
            </el-row>
                <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('profile.email'))" >
                    <el-input v-model="form.email" placeholder="example@gmail.com" width="300px" readonly="readonly"  />
                </el-form-item>
                <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('profile.username'))" >
                    <el-input v-model="form.username" placeholder="username1" readonly="readonly" />
                </el-form-item>
                <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('profile.fullname'))" >
                    <el-input v-model="form.fullname" placeholder="Nguyen Van A"  />
                </el-form-item>
                <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.phone'))" >
                    <el-input v-model="form.phone_number" placeholder="091234567"  />
                </el-form-item>
                <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('profile.password'))" >
                    <el-input v-model="form.password" placeholder="123@123123"  />
                </el-form-item>
                <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('profile.role'))" >
                    <el-tag v-for="(item, index) in form.roles" class="ml-2" type="success" v-bind:key="index">
                        {{ item.name }}
                    </el-tag>
                </el-form-item>
            <el-row type="flex" :gutter="1" >
                <el-col :span="24" type="flex" justify="space-between">
                    <el-button size="small" type="primary" @click="submitForm" :icon="Select">{{ $filters.capitalize($t('action.save')) }}</el-button>
                    <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                    <router-link :to="{name: 'Dashboard'}" style="margin-left: 10px;">
                        <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                    </router-link>
                </el-col>
            </el-row>
        </el-form>
    </el-main>
    </el-container>
</template>
<script setup>

</script>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus
} from '@element-plus/icons-vue'
</script>
<script>
import { ElMessage, ElMessageBox } from 'element-plus';
import { profile, editProfile, uploadAvatar } from '@/api/login';
import {avatar_image} from '@/constants/configs';
export default {
    name: "ProfileIndex",
    data() {
        return {
            form: {
                username: '',
                fullname: '',
                phone_number: '',
                password: '',
                email: '',
                role: 0,
                role_name: '',
                avatar: ''
            },
            file: null
        };
    },
    created(){
        this.getProfile();
    },
    methods: {
        async getProfile(){
            this.$store.state.loading = true;
            let res = await profile();
            this.$store.state.loading = false;
            
            if (res != null && res.status == 200 && res.data != null) {
                this.form = res.data;
                if (this.form.avatar != '') {
                    this.form.avatar = this.form.avatar != null && this.form.avatar != '' ? process.env.VUE_APP_BASE_HOST + this.form.avatar : null;
                }
            }
        },
        async submitForm(){
            this.$store.state.loading = true;
            let formData = new FormData();
            formData.append('fullname', this.form.fullname);
            formData.append('phone_number', this.form.phone_number);
            let res = await editProfile(formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.updated-success')),
                    type: 'success',
                });
            } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.updated-success')),
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.getProfile();
        },
        async addFile(e){
            if(e.raw == null || e.raw.type.indexOf('image/') !== 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.wrong-format')),
                    type: 'error',
                });
                return;
            }
            const isLt2M = e.raw.size / 1024 / 1024 < avatar_image.size;
            if (!isLt2M) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.file-less-or-equal'))+ avatar_image.size + 'MB',
                    type: 'error',
                });
                return;
            }
            var tmppath = URL.createObjectURL(e.raw);
            const img = new Image();
            img.src = tmppath;
            // await img.decode();
            // if (Math.abs(img.width - avatar_image.width) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-width-equal'))+ avatar_image.width + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            // if (Math.abs(img.height - avatar_image.height) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-height-equal'))+ avatar_image.height + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            ElMessageBox.confirm(
                this.$filters.capitalize(this.$t('action.upload')),
            'Cảnh báo',
            {
              confirmButtonText: this.$filters.capitalize(this.$t('action.ok')),
              cancelButtonText: this.$filters.capitalize(this.$t('action.cancel')),
              type: 'success',
              draggable: true
            }
          ).then( async () =>  {
            if (e != null) {
                this.$store.state.loading = true;
                let formData = new FormData();
                formData.append('image', e.raw);
                console.log(e);
                this.$store.state.loading = false;
                let res = await uploadAvatar(formData);
                if (res != null && res.status == 200)  {
                    ElMessage({
                        message: this.$filters.capitalize(this.$t('title.save-success')),
                        type: 'success',
                    });
                    this.getProfile();
                } else {
                    ElMessage({
                        message: this.$filters.capitalize(this.$t('title.save-failure')),
                        type: 'error',
                    });
                }
            }
          }).catch(() => {
            console.log('no');
          });
        },
        beforeUpload(rawFile) {
            console.log(rawFile);
            return false;
        }
    }
};
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
<style scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
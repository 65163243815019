<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
        <el-form-item :label="$filters.capitalize($t('title.feature-image'))" style="max-width: 600px">
            </el-form-item>
            <el-text class="mx-1" type="danger">{{ $filters.capitalize($t('title.image-have-size')) + news_feature_image.width +"x"+news_feature_image.height+"px" }}</el-text>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                    auto-upload="false"
                    accept="image/jpg, image/jpeg, image/png"
                >

                <img v-if="form.feature_image" :src="form.feature_image" class="avatar" style="max-width: 600px;" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
        <el-form-item :label="$filters.capitalize($t('title.video'))" style="max-width: 600px">
            </el-form-item>
            <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-progress v-if="upload_progress.show" :text-inside="true" :stroke-width="26" :percentage="upload_progress.progress" />
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeVideo"
                    :before-upload="beforeUploadVideo"
                    auto-upload="false"
                    accept="video/mp4,video/x-m4v,video/*"
                >
                <video v-if="form.video" width="320" height="240" controls>
                    <source :src="form.video" type="video/mp4"/>
                    <source :src="form.video" type="video/ogg"/>
                    <source :src="form.video" type="video/x-m4k"/>
                    
                </video>
                    <el-icon v-if="form.video" :size="32" color="#f56c6c"><Edit /></el-icon>
                    <el-icon v-else class="avatar-uploader-icon" color="#67c23a"><Plus /></el-icon>
                </el-upload>
                
            </el-col>
        </el-row>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.category'))" >
                <el-select
                    v-model="form.video_category_ids"
                    multiple
                    placeholder="Select"
                    style="width: 240px"
                    >
                    <el-option
                        v-for="item in categories"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('article.tags'))" >
                <el-select
                    v-model="form.tags"
                    multiple
                    placeholder="Tags"
                    style="width: 240px"
                    allow-create="true"
                    filterable="true"
                    >
                    <el-option
                        v-for="item in tags"
                        :key="item"
                        :label="item"
                        :value="item"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.name'))" >
                <el-input v-model="form.name" placeholder="name"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.keywords'))" >
                <el-input v-model="form.keywords" placeholder="Keywords"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.meta-description'))" >
                <el-input type="textarea" v-model="form.meta_description" placeholder=""  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.status'))" >
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="$filters.capitalize($t('title.'+item.label))"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('article.public-time'))" >
                <el-date-picker @change="changePublicTime" v-model="form.public_time" type="datetime" format="YYYY-MM-DD HH:mm:ss"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('video.link-shop'))" >
                <el-select
                    v-model="form.shop_link_id"
                    placeholder="Select"
                    style="width: 240px"
                    >
                    <el-option
                        v-for="item in shops"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('video.link-product'))" >
                <el-select
                    v-model="form.product_link_id"
                    placeholder="Select"
                    style="width: 240px"
                    >
                    <el-option
                        v-for="item in products"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                    </el-select>
            </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">{{ $filters.capitalize($t('action.save')) }}</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                <router-link :to="{name: 'ShortVideoIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus,
  Edit
} from '@element-plus/icons-vue'
</script>
<script>
import { list as listShops } from '@/api/shop';
import {list as listProduct} from '@/api/product';
import {list as listCategory} from '@/api/video_category';
import { add, upload } from '@/api/short_video';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { news_feature_image, short_video } from '@/constants/configs';

export default {
    name: "VideoCreate",
    data(){
        return {
            form: {
                name: '',
                feature_image: '',
                slug: '',
                keywords: '',
                meta_description: '',
                image: null,
                status: 0,
                video_category_ids: [],
                tags: [],
                public_time: (new Date()),
                video: '',
                shop_link_id: 0,
                product_link_id: 0
            },
            statusList: [
                {
                    val: 0,
                    label: 'no-active'
                },
                {
                    val: 1,
                    label: 'active'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            categories: [],
            tags: [],
            products: [],
            shops: [],
            upload_progress: {
                progress: 0,
                result: null,
                show: false
            },
            chunkSize: 1000000,
            chunkNumber: 0,
            currentChunkSize: 0
        };
    },
    created(){
        this.getCategory();
        this.getProducts();
        this.getShops();
    },
    methods: {
        async getCategory(){
            let res = await listCategory('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.categories = res.data.data;
            }
        },
        async getShops(){
            let res = await listShops('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.shops = res.data.data;
            }
        },
        async getProducts(){
            let res = await listProduct('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.products = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.name'),
                    type: 'error',
                });
                return;
            }
            if (this.form.keywords.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.keywords'),
                    type: 'error',
                });
                return;
            }
            if (this.form.image == null) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.feature-image'),
                    type: 'error',
                });
                return;
            }
            if (this.form.video == null) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.video'),
                    type: 'error',
                });
                return;
            }
            if (this.form.meta_description.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.meta-description'),
                    type: 'error',
                });
                return;
            }
            if (this.form.video_category_ids.length <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.category'),
                    type: 'error',
                });
                return;
            }
            if (this.form.public_time.getTime() < (new Date()).getTime()) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('article.public-time')) + " >= " + this.$t('article.now'),
                    type: 'error',
                });
                return;
            }
            
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('keywords', this.form.keywords);
            formData.append('meta_description', this.form.meta_description);
            formData.append('public_time', this.form.public_time.getTime()/1000);
            for(var i = 0; i < this.form.tags.length; i++){
                formData.append('tags[]', this.form.tags[i]);
            }
            for(var j = 0; j < this.form.video_category_ids.length; j++) {
                formData.append('video_category_ids[]', Number(this.form.video_category_ids[j]));
            }
            if (this.form.image != null) {
                formData.append('feature_image', this.form.image);
            }
            if (this.form.video != '') {
                
                formData.append('video', this.form.video.replace(process.env.VUE_APP_BASE_HOST, ''));
            }
            if (this.form.shop_link_id > 0) {
                formData.append('shop_link_id', this.form.shop_link_id);
            }
            if (this.form.product_link_id > 0) {
                formData.append('product_link_id', this.form.product_link_id);
            }
            formData.append('status', this.form.status);
            this.$store.state.loading = true;
            let res = await add(formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    type: 'success',
                });
                this.$router.push({ path: '/short-video' });
            } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-failure')),
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.form = {
                name: '',
                feature_image: '',
                slug: '',
                keywords: '',
                meta_description: '',
                image: null,
                status: 0,
                video_category_ids: [],
                tags: [],
                public_time: (new Date()),
                video: '',
                shop_link_id: 0,
                product_link_id: 0
            };
            this.upload_progress = {
                progress: 0,
                result: null,
                show: false
            };
            this.chunkSize = 1000000;
            this.chunkNumber = 0;
            this.currentChunkSize = 0;
        },
        async changeFile(e){
            if(e.raw == null || e.raw.type.indexOf('image/') !== 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.wrong-format')),
                    type: 'error',
                });
                return;
            }
            const isLt2M = e.raw.size / 1024 / 1024 < news_feature_image.size;
            if (!isLt2M) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.file-less-or-equal'))+ news_feature_image.size + 'MB',
                    type: 'error',
                });
                return;
            }
            var tmppath = URL.createObjectURL(e.raw);
            const img = new Image();
            img.src = tmppath;
            await img.decode();
            // if (Math.abs(img.width - news_feature_image.width) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-width-equal'))+ news_feature_image.width + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            // if (Math.abs(img.height - news_feature_image.height) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-height-equal'))+ news_feature_image.height + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            this.form.image = e.raw;
            this.form.feature_image = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        },
        changePublicTime(e) {
            let t = new Date(e);
            if (t.getTime() < (new Date()).getTime()) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('article.public-time')) + " >= " + this.$t('article.now'),
                    type: 'error',
                });
                return;
            }
        },
        async changeVideo(e){
            if(e.raw == null || e.raw.type.indexOf('video/') !== 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.wrong-format')),
                    type: 'error',
                });
                return;
            }
            const isLt2M = e.raw.size / 1024 / 1024 < short_video.size;
            if (!isLt2M) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.file-less-or-equal'))+ short_video.size + 'MB',
                    type: 'error',
                });
                return;
            }
            let file = e.raw;
            this.upload_progress.progress = 0;
            this.upload_progress.result = null;
            const blockCount = Math.ceil(file.size / this.chunkSize);
            this.chunkNumber = 0;
            const identifier = `${file.size}-${file.name.replace('.', '')}`; 
            this.upload_progress.show = true;
            this.$store.state.loading = true;
            while (this.chunkNumber < blockCount) {
                const start = this.chunkNumber * this.chunkSize;
                const end = Math.min(file.size, start + this.chunkSize);
                this.currentChunkSize = this.chunkSize;
                if (this.chunkNumber + 1 === blockCount) {
                    this.currentChunkSize = file.size - start;
                }
                const params = new FormData();
                params.append('resumableChunkNumber', this.chunkNumber);
                params.append('resumableChunkSize', this.currentChunkSize);
                params.append('resumableCurrentChunkSize', this.currentChunkSize);
                params.append('resumableTotalSize', file.size);
                params.append('resumableType', file.type);
                params.append('resumableIdentifier', identifier);
                params.append('resumableFilename', file.name);
                params.append('resumableRelativePath', file.name);
                params.append('resumableTotalChunks', blockCount);
                params.append('file', file.slice(start, end), file.name);

                let res = await upload(params);
                if (res != null && res.status == 200 && res.data != null) {
                    this.chunkNumber++;
                    if (res.data.done != null) {
                        this.upload_progress.progress = res.data.done;
                    }
                    if (res.data.url_base != null) {
                        this.form.video = process.env.VUE_APP_BASE_HOST + res.data.url_base;
                        this.upload_progress.progress = 100;
                        this.upload_progress.show = false;
                        ElMessage({
                            message: this.$filters.capitalize(this.$t('title.upload-success')),
                            type: 'success',
                        });
                        this.chunkNumber = 0;
                        this.$store.state.loading = false;
                        return;
                    }
                } else {
                    ElMessage({
                        message: this.$filters.capitalize(this.$t('title.upload-failure')),
                        type: 'error',
                    });
                    this.chunkNumber = 0;
                    this.$store.state.loading = false;
                    return;
                }
            }
            this.$store.state.loading = false;
            
        },
        beforeUploadVideo(rawFile){
            console.log(rawFile);
            return false;
        },
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
            <el-row type="flex" :gutter="4" style="width: 100%;">
                <el-col :span="24" style="text-align: left;">
                    <el-button size="small" class="ms-10" type="warning">
                        <el-icon style="margin-right: 5px;">
                            <download />
                        </el-icon>
                        <download-excel :data="template_providers" :name="'provider_template.xlsx'">
                        {{ $filters.capitalize($t('action.download'))}} {{ $t('title.format')}} {{ $t('menu.provider')}}
                        </download-excel>
                    </el-button>
                </el-col>
            </el-row>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-upload
                ref="upload"
                class="upload-demo"
                :limit="1"
                :on-change="changeFile"
                :before-upload="beforeUpload"
                auto-upload="false"
                accept="application/vnd.ms-excel, application/msexcel, application/x-msexcel, application/x-ms-excel, application/x-excel, application/x-dos_ms_excel, application/xls, application/x-xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
            <template #trigger>
                <el-button size="small" class="ms-10" type="primary">
                    <el-icon style="margin-right: 5px;">
                        <upload />
                    </el-icon>
                    {{ $filters.capitalize($t('action.select-file')) }}
                </el-button>
            </template>
            </el-upload>
        </el-row>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" >
                <el-table :data="form.products" style="overflow-x: scroll;" :row-class-name="tableRowClassName">
                    <el-table-column label="#" width="50">
                        <template #default="scope">
                            {{ scope.$index }}
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.name'))" width="100">
                        <template #default="scope">
                            <el-input v-model="scope.row.name" size="small" step="1" min="1" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.description'))" width="600">
                        <template #default="scope">
                            <ckeditor :editor="editor" :id="'ck-description-'+scope.$index"  v-model="scope.row.description" :config="editorConfig"></ckeditor>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.address'))" width="150">
                        <template #default="scope">
                            <el-input v-model="scope.row.address" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.phone'))" width="150">
                        <template #default="scope">
                            <el-input v-model="scope.row.phone" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.website'))" width="150">
                        <template #default="scope">
                            <el-input v-model="scope.row.website" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('facebook'))" width="150">
                        <template #default="scope">
                            <el-input v-model="scope.row.facebook" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('twitter'))" width="150">
                        <template #default="scope">
                            <el-input v-model="scope.row.twitter" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('zalo'))" width="150">
                        <template #default="scope">
                            <el-input v-model="scope.row.zalo" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('tiktok'))" width="150">
                        <template #default="scope">
                            <el-input v-model="scope.row.tiktok" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('instagram'))" width="150">
                        <template #default="scope">
                            <el-input v-model="scope.row.instagram" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.status'))" width="100">
                        <template #default="scope">
                                <el-select v-model="scope.row.status">
                                    <el-option
                                        v-for="item in statusList"
                                        :key="item.val"
                                        :label="item.label"
                                        :value="item.val"
                                    />
                             </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.operators'))" width="100">
                        <template #default="scope">
                            <el-button size="small" type="danger" @click="removeProduct(scope.$index)" :icon="Delete">{{ $filters.capitalize($t('action.delete')) }}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">{{ $filters.capitalize($t('action.save')) }}</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                <router-link :to="{name: 'ProviderIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>
import {
  Select,
  Back,
  Refresh,
  Download,
  Upload
} from '@element-plus/icons-vue'
</script>
<script>
import { importProvider } from '@/api/provider';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import readXlsxFile from 'read-excel-file';
import { provider_template } from '@/constants/templates';
import { template_providers } from '@/templates/providers';
// import { product_feature_image, product_image, distance_tolerance } from '@/constants/configs';
export default {
    name: "ProviderImport",
    data(){
        return {
            dialogVisible: false,
            form: {
                products: [],
                shop_id: 0
            },
            statusList: [
                {
                    val: 0,
                    label: 'no-active'
                },
                {
                    val: 1,
                    label: 'active'
                }
            ],
            publicList: [
                {
                    val: 0,
                    label: 'no-public'
                },
                {
                    val: 1,
                    label: 'public'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            template_providers: template_providers
        };
    },
    created(){
    },
    methods: {
        async submitForm(){
            if (this.form.products.length <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('menu.provider'),
                    type: 'error',
                });
                return;
            }
            
            let formData = new FormData();
            for(let i = 0; i < this.form.products.length; i++) {
                formData.append('providers['+i+'][name]', this.form.products[i].name);
                formData.append('providers['+i+'][description]', this.form.products[i].description);
                formData.append('providers['+i+'][address]', this.form.products[i].address);
                formData.append('providers['+i+'][phone]', this.form.products[i].phone);
                formData.append('providers['+i+'][facebook]', this.form.products[i].facebook);
                formData.append('providers['+i+'][twitter]', this.form.products[i].twitter);
                formData.append('providers['+i+'][zalo]', this.form.products[i].zalo);
                formData.append('providers['+i+'][tiktok]', this.form.products[i].tiktok);
                formData.append('providers['+i+'][instagram]', this.form.products[i].instagram);
                formData.append('providers['+i+'][website]', this.form.products[i].website);
                formData.append('providers['+i+'][status]', this.form.products[i].status);
            }
            this.$store.state.loading = true;
            let res = await importProvider(formData);
            this.$store.state.loading = false;
            console.log(res);
            if (res != null && res.status == 200) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    type: 'success',
                });
                this.$router.push({ path: '/provider' });
            } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-failure')),
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.form = {
                product: [],
            };
        },
        async changeFile(e){
            if(e.raw == null || (e.raw.type.indexOf('application/vnd.ms-excel') !== 0 
            && e.raw.type.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') !== 0
            && e.raw.type.indexOf('application/msexcel') !== 0
            && e.raw.type.indexOf('application/x-msexcel') !== 0
            && e.raw.type.indexOf('application/x-ms-excel') !== 0
            && e.raw.type.indexOf('application/x-excel') !== 0
            && e.raw.type.indexOf('application/x-dos_ms_excel') !== 0
            && e.raw.type.indexOf('application/xls') !== 0
            && e.raw.type.indexOf('application/x-xls') !== 0
            )) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.wrong-format')),
                    type: 'error',
                });
                return;
            }
            let data = await readXlsxFile(e.raw, { sheet: 'Sheet1', schema: provider_template });
            if (data.errors == null || data.errors.length == 0) {
                this.form.products = data.rows;
            }
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        },
        tableRowClassName(row){
            if (row.rowIndex % 2 == 1) {
                return 'warning-row';
            } else 
                return 'success-row';
        },
        removeProduct(index) {
            this.form.products.splice(index, 1);
        },
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
<style>
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-9);
}
.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
</style>
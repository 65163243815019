const template_providers = [
    {
        INDEX: 1,
        NAME: 'Nhà cung cấp 1',
        DESCRIPTION: 'Mô tả nhà cung cấp 1',
        ADDRESS: 'Địa chỉ nhà cung cấp 1',
        PHONE: '0938123123',
        WEBSITE: 'http://example.com',
        FACEBOOK: 'https://facebook.com',
        ZALO: 'https://chat.zalo.me/0938123123',
        TIKTOK: 'https://tiktok.com',
        TWITTER: 'https://twitter.com',
        STATUS: 1
    },
    {
        INDEX: 2,
        NAME: 'Nhà cung cấp 2',
        DESCRIPTION: 'Mô tả nhà cung cấp 2',
        ADDRESS: 'Địa chỉ nhà cung cấp 2',
        PHONE: '0938123123',
        WEBSITE: 'http://example.com',
        FACEBOOK: 'https://facebook.com',
        ZALO: 'https://chat.zalo.me/0938123123',
        TIKTOK: 'https://tiktok.com',
        TWITTER: 'https://twitter.com',
        STATUS: 1
    }
];
export {template_providers};
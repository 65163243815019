import { createRouter, createWebHistory } from "vue-router"
import Dashboard from '../views/Dashboard.vue'
import LoginPage from '../views/LoginPage.vue'
import { Role } from "@/constants/role";
import CustomerIndex from '../views/customer/IndexPage.vue'
import StatisticIndex from '../views/statistic/IndexPage.vue'
import ProductIndex from '../views/product/IndexPage.vue'
import ProductEdit from '../views/product/EditPage.vue'
import ProductAdd from '../views/product/AddPage.vue'
import ProductImport from '../views/product/ImportPage.vue'
import OrderIndex from '../views/order/IndexPage.vue'
import OrderAdd from '../views/order/AddPage.vue'
import OrderEdit from '../views/order/EditPage.vue'
import ProfileIndex from '../views/profile/ProfilePage.vue'
import NewsIndex from '../views/news/IndexPage.vue'
import NewsEdit from '../views/news/EditPage.vue'
import NewsAdd from '../views/news/AddPage.vue'
import ShortVideoIndex from '../views/shortvideo/IndexPage.vue'
import ShortVideoEdit from '../views/shortvideo/EditPage.vue'
import ShortVideoAdd from '../views/shortvideo/AddPage.vue'
import ImportStockIndex from '../views/importstock/IndexPage.vue'
import ImportStockEdit from '../views/importstock/EditPage.vue'
import ImportStockAdd from '../views/importstock/AddPage.vue'
import ImportStockImport from '../views/importstock/ImportPage.vue'
import ExportStockIndex from '../views/exportstock/IndexPage.vue'
import ExportStockEdit from '../views/exportstock/EditPage.vue'
import ExportStockAdd from '../views/exportstock/AddPage.vue'
import ExportStockImport from '../views/exportstock/ImportPage.vue'
import ShopIndex from '../views/shop/IndexPage.vue'
import ShopEdit from '../views/shop/EditPage.vue'
import ShopAdd from '../views/shop/AddPage.vue'
import ProviderIndex from '../views/provider/IndexPage.vue'
import ProviderEdit from '../views/provider/EditPage.vue'
import ProviderAdd from '../views/provider/AddPage.vue'
import ProviderImport from '../views/provider/ImportPage.vue'
import SettingIndex from '../views/settings/SettingPage.vue'
import CostTypeIndex from '../views/costtype/IndexPage.vue'
import CostTypeEdit from '../views/costtype/EditPage.vue'
import CostTypeAdd from '../views/costtype/AddPage.vue'
import CostIndex from '../views/cost/IndexPage.vue'
import CostEdit from '../views/cost/EditPage.vue'
import CostAdd from '../views/cost/AddPage.vue'
import SearchEngineIndex from '../views/searchengine/IndexPage.vue'
import SearchEngineEdit from '../views/searchengine/EditPage.vue'
import SearchEngineAdd from '../views/searchengine/AddPage.vue'
import SearchResultIndex from '../views/searchresult/IndexPage.vue'
import SearchResultEdit from '../views/searchresult/EditPage.vue'
import ResultChartView from '../views/searchresult/ChartView.vue'
import ShipOrderIndex from '../views/shiporder/IndexPage.vue'
import ShipOrderEdit from '../views/shiporder/EditPage.vue'
import ShipOrderAdd from '../views/shiporder/AddPage.vue'
import store from "@/store";
const routes = [
    {
        path: "/",
        name: "Dashboard",
        redirect: '/dashboard',
        component: Dashboard,
        meta: {title: 'Dashboard', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        children: [{
            path: 'dashboard',
            name: 'Dashboard',
            component: Dashboard,
            meta: { title: 'dashboard', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]}
        }]
    },
    {
        path: "/login",
        name: "LoginPage",
        component: LoginPage
    },
    {
      path: '/news',
      // component: EndpointIndex,
      redirect: '/news/list',
      name: 'News',
      meta: { title: 'News', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
      children: [
        {
          path: 'list',
          name: 'NewsIndex',
          component: NewsIndex,
          meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]}
        },
        {
          path: 'add',
          name: 'NewsAdd',
          component: NewsAdd,
          meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
          hidden: true
        },
        {
          path: 'edit/:id',
          name: 'NewsEdit',
          component: NewsEdit,
          meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
          hidden: true
        }
      ]
    },
    {
      path: '/short-video',
      // component: EndpointIndex,
      redirect: '/short-video/list',
      name: 'ShortVideo',
      meta: { title: 'Short Video', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
      children: [
        {
          path: 'list',
          name: 'ShortVideoIndex',
          component: ShortVideoIndex,
          meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]}
        },
        {
          path: 'add',
          name: 'ShortVideoAdd',
          component: ShortVideoAdd,
          meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
          hidden: true
        },
        {
          path: 'edit/:id',
          name: 'ShortVideoEdit',
          component: ShortVideoEdit,
          meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
          hidden: true
        }
      ]
    },
    {
        path: '/product',
        // component: ProductIndex,
        redirect: '/product/list',
        name: 'Product',
        meta: { title: 'Product Manager', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        children: [
          {
            path: 'list',
            name: 'ProductIndex',
            component: ProductIndex,
            meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]}
          },
          {
            path: 'add',
            name: 'ProductAdd',
            component: ProductAdd,
            meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
            hidden: true
          },
          {
            path: 'import',
            name: 'ProductImport',
            component: ProductImport,
            meta: { title: 'Import', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
            hidden: true
          },
          {
            path: 'edit/:id',
            name: 'ProductEdit',
            component: ProductEdit,
            meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
            hidden: true
          }
        ]
    },
    {
        path: '/order',
        //component: OrderIndex,
        redirect: '/order/list',
        name: 'Order',
        meta: { title: 'Order Manager', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        children: [
          {
            path: 'list',
            name: 'OrderIndex',
            component: OrderIndex,
            meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]}
          },
          {
            path: 'add',
            name: 'OrderAdd',
            component: OrderAdd,
            meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
            hidden: true
          },
          {
            path: 'edit/:id',
            name: 'OrderEdit',
            component: OrderEdit,
            meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
            hidden: true
          }
        ]
    },
    {
      path: '/import-stock',
      //component: OrderIndex,
      redirect: '/import-stock/list',
      name: 'ImportStock',
      meta: { title: 'Import Stock', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
      children: [
        {
          path: 'list',
          name: 'ImportStockIndex',
          component: ImportStockIndex,
          meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]}
        },
        {
          path: 'add',
          name: 'ImportStockAdd',
          component: ImportStockAdd,
          meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
          hidden: true
        },
        {
          path: 'upload',
          name: 'ImportStockImport',
          component: ImportStockImport,
          meta: { title: 'Import', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
          hidden: true
        },
        {
          path: 'edit/:id',
          name: 'ImportStockEdit',
          component: ImportStockEdit,
          meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
          hidden: true
        }
      ]
  },
  {
    path: '/export-stock',
    //component: OrderIndex,
    redirect: '/export-stock/list',
    name: 'ExportStock',
    meta: { title: 'Export Stock', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
    children: [
      {
        path: 'list',
        name: 'ExportStockIndex',
        component: ExportStockIndex,
        meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]}
      },
      {
        path: 'add',
        name: 'ExportStockAdd',
        component: ExportStockAdd,
        meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        hidden: true
      },
      {
        path: 'upload',
        name: 'ExportStockImport',
        component: ExportStockImport,
        meta: { title: 'Import', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        hidden: true
      },
      {
        path: 'edit/:id',
        name: 'ExportStockEdit',
        component: ExportStockEdit,
        meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        hidden: true
      }
    ]
  },
    {
        path: '/shop',
        //component: UserEndpointIndex,
        redirect: '/shop/list',
        name: 'Shop',
        meta: { title: 'Shop', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        children: [
          {
            path: 'list',
            name: 'ShopIndex',
            component: ShopIndex,
            meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]}
          },
          {
            path: 'add',
            name: 'ShopAdd',
            component: ShopAdd,
            meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
            hidden: true
          },
          {
            path: 'edit/:id',
            name: 'ShopEdit',
            component: ShopEdit,
            meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
            hidden: true
          }
        ]
    },
    {
      path: '/provider',
      //component: UserEndpointIndex,
      redirect: '/provider/list',
      name: 'Provider',
      meta: { title: 'Provider', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
      children: [
        {
          path: 'list',
          name: 'ProviderIndex',
          component: ProviderIndex,
          meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]}
        },
        {
          path: 'add',
          name: 'ProviderAdd',
          component: ProviderAdd,
          meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
          hidden: true
        },
        {
          path: 'import',
          name: 'ProviderImport',
          component: ProviderImport,
          meta: { title: 'Import', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
          hidden: true
        },
        {
          path: 'edit/:id',
          name: 'ProviderEdit',
          component: ProviderEdit,
          meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
          hidden: true
        }
      ]
  },
  {
    path: '/cost-type',
    //component: UserEndpointIndex,
    redirect: '/cost-type/list',
    name: 'CostType',
    meta: { title: 'Cost Type', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
    children: [
      {
        path: 'list',
        name: 'CostTypeIndex',
        component: CostTypeIndex,
        meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]}
      },
      {
        path: 'add',
        name: 'CostTypeAdd',
        component: CostTypeAdd,
        meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        hidden: true
      },
      {
        path: 'edit/:id',
        name: 'CostTypeEdit',
        component: CostTypeEdit,
        meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        hidden: true
      }
    ]
  },
  {
    path: '/cost',
    //component: UserEndpointIndex,
    redirect: '/cost/list',
    name: 'Cost',
    meta: { title: 'Cost', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
    children: [
      {
        path: 'list',
        name: 'CostIndex',
        component: CostIndex,
        meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]}
      },
      {
        path: 'add',
        name: 'CostAdd',
        component: CostAdd,
        meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        hidden: true
      },
      {
        path: 'edit/:id',
        name: 'CostEdit',
        component: CostEdit,
        meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        hidden: true
      }
    ]
  },
  {
    path: '/search-engine',
    //component: UserEndpointIndex,
    redirect: '/search-engine/list',
    name: 'SearchEngine',
    meta: { title: 'Analytic Price', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
    children: [
      {
        path: 'list',
        name: 'SearchEngineIndex',
        component: SearchEngineIndex,
        meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]}
      },
      {
        path: 'add',
        name: 'SearchEngineAdd',
        component: SearchEngineAdd,
        meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        hidden: true
      },
      {
        path: 'edit/:id',
        name: 'SearchEngineEdit',
        component: SearchEngineEdit,
        meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        hidden: true
      }
    ]
  },
  {
    path: '/search-result',
    //component: UserEndpointIndex,
    redirect: '/search-result/list',
    name: 'SearchResult',
    meta: { title: 'Analytic Price Result', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
    children: [
      {
        path: 'list',
        name: 'SearchResultIndex',
        component: SearchResultIndex,
        meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]}
      },
      {
        path: 'edit/:id',
        name: 'SearchResultEdit',
        component: SearchResultEdit,
        meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        hidden: true
      },
      {
        path: 'chart-view/:jobId',
        name: 'ResultChartView',
        component: ResultChartView,
        meta: { title: 'Result Chart View', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        hidden: true
      }
    ]
  },
  {
    path: '/ship-order',
    //component: UserEndpointIndex,
    redirect: '/ship-order/list',
    name: 'ShipOrder',
    meta: { title: 'Ship Order', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
    children: [
      {
        path: 'list',
        name: 'ShipOrderIndex',
        component: ShipOrderIndex,
        meta: { title: 'list', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]}
      },
      {
        path: 'edit/:id',
        name: 'ShipOrderEdit',
        component: ShipOrderEdit,
        meta: { title: 'Edit', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        hidden: true
      },
      {
        path: 'add',
        name: 'ShipOrderAdd',
        component: ShipOrderAdd,
        meta: { title: 'Add', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
        hidden: true
      }
    ]
  },
    {
        path: "/profile",
        name: "Profile",
        redirect: null,
        component: ProfileIndex,
        meta: {title: 'Profile', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
    },
    {
      path: "/setting",
      name: "SettingIndex",
      redirect: null,
      component: SettingIndex,
      meta: {title: 'Setting', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
    },
    {
      path: "/customer",
      name: "CustomerIndex",
      redirect: null,
      component: CustomerIndex,
      meta: {title: 'Customer', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
    },
    {
      path: "/statistic",
      name: "StatisticIndex",
      redirect: null,
      component: StatisticIndex,
      meta: {title: 'Statistic', requiresAuth: true, authorize: [Role.RoleTypeShopAdmin, Role.RoleTypeShopEditor]},
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
  });
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.token != null && typeof store.getters.token != 'undefined' && store.state.user != 'undefined' && store.state.user.user != 'undefined' && store.state.user.user.email != null) {
        next();
        return;
    }
    next("/login");
} else {
    next();
}
});
  export default router; 
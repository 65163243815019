<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
        <el-text class="mx-1" type="danger">{{ $filters.capitalize($t('title.image-have-size')) + product_feature_image.width +"x"+product_feature_image.height+"px" }}</el-text>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                    auto-upload="false"
                    accept="image/jpg, image/jpeg, image/png"
                >

                <img v-if="form.feature_image" :src="form.feature_image" class="avatar" style="max-width: 600px;" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.category'))" >
                <el-select
                    v-model="form.product_category_ids"
                    multiple
                    placeholder="Select"
                    style="width: 240px"
                    >
                    <el-option
                        v-for="item in categories"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item style="max-width: 600px" label="Shop" >
                <el-select v-model="form.shop_id" @change="changeShopId">
                <el-option
                    v-for="(item, index) in shops"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('menu.provider'))" >
                <el-select
                    v-model="form.provider_id"
                    placeholder="Select"
                    style="width: 240px"
                    >
                    <el-option
                        v-for="item in providers"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('product.features'))" >
                <el-select
                    v-model="form.features"
                    multiple
                    placeholder="Features"
                    style="width: 240px"
                    allow-create="true"
                    filterable="true"
                    >
                    <el-option
                        v-for="item in features"
                        :key="item"
                        :label="item"
                        :value="item"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.name'))" >
                <el-input v-model="form.name" placeholder="name" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('title.length'))" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.length" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('title.width'))" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.width" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('title.height'))" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.height" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('title.weight'))" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.weight" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" label="Barcode" >
                <el-input v-model="form.barcode" placeholder="barcode" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" label="Sku" >
                <el-input v-model="form.sku" placeholder="sku" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" label="Slug" >
                <el-input v-model="form.slug" placeholder="slug" width="300px" readonly />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('product.import-price'))" >
                <el-input-number min="0" step="1" v-model="form.import_price" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('product.show-price'))" >
                <el-input-number min="0" step="1" v-model="form.show_price" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('product.selling-price'))" >
                <el-input-number min="0" step="1" v-model="form.selling_price" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.keywords'))" >
                <el-input v-model="form.keywords" placeholder="Keywords" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.meta-description'))" >
                <el-input type="textarea" v-model="form.meta_description" placeholder="" width="300px"  />
            </el-form-item>
            <el-form-item :label="$filters.capitalize($t('title.description'))" style="max-width: 600px">
            </el-form-item>
            <ckeditor :editor="editor" id="ck-description"  v-model="form.description" :config="editorConfig"></ckeditor>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.status'))" >
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="$filters.capitalize($t('title.'+item.label))"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
          <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('title.ship-type'))" style="max-width: 600px;">
                <el-select v-model="form.type_id" >
                <el-option
                    v-for="(item, index) in productShipTypes"
                    :key="index"
                    :label="$filters.capitalize($t('title.'+item.label))"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
          <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.is-public'))" >
                <el-select v-model="form.is_public" >
                <el-option
                    v-for="(item, index) in publicList"
                    :key="index"
                    :label="$filters.capitalize($t('title.'+item.label))"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('title.images'))" >
                <el-upload
                    v-model:file-list="image_previews"
                    list-type="picture-card"
                    :before-upload="beforeImage"
                    :on-change="changeImage"
                    auto-upload="false"
                    accept="image/jpg, image/jpeg, image/png"
                >
                    <el-icon><Plus /></el-icon>
                </el-upload>

                <el-dialog v-model="dialogVisible">
                    <img w-full :src="dialogImageUrl" alt="Preview Image" />
                </el-dialog>
            </el-form-item>
        </el-row>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">{{ $filters.capitalize($t('action.save')) }}</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                <router-link :to="{name: 'ProductIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>
import {
  Select,
  Back,
  Refresh,
  Plus
} from '@element-plus/icons-vue'
</script>
<script>
import { list as listShops } from '@/api/shop';
import {list as listCategory} from '@/api/product_category';
import {list as listProvider} from '@/api/provider';
import { add } from '@/api/product';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { product_feature_image, product_image } from '@/constants/configs';
export default {
    name: "ProductEdit",
    data(){
        return {
            dialogVisible: false,
            form: {
                name: '',
                feature_image: '',
                slug: '',
                description: '',
                keywords: '',
                meta_description: '',
                image: null,
                status: 0,
                is_public: 0,
                shop_id: 0,
                product_category_ids: [],
                features: [],
                barcode: '',
                sku: '',
                import_price: 0,
                selling_price: 0,
                show_price: 0,
                provider_id: 0,
                images: [],
                length: 0,
                width: 0,
                height: 0,
                weight: 0,
                type_id: 0,
            },
            statusList: [
                {
                    val: 0,
                    label: 'no-active'
                },
                {
                    val: 1,
                    label: 'active'
                }
            ],
            publicList: [
                {
                    val: 0,
                    label: 'no-public'
                },
                {
                    val: 1,
                    label: 'public'
                }
            ],
            productShipTypes: [
                {
                    val: 0,
                    label: 'normal'
                },
                {
                    val: 1,
                    label: 'frozen'
                },
                {
                    val: 2,
                    label: 'fragile'
                },
                {
                    val: 3,
                    label: 'danger'
                },
                {
                    val: 4,
                    label: 'ex-danger'
                },
                {
                    val: 5,
                    label: 'other'
                },
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            shops: [],
            categories: [],
            features: [],
            image_previews: [],
            providers: []
        };
    },
    created(){
        this.getShops();
        this.getCategory();
    },
    methods: {
        async getShops(){
            let res = await listShops('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.shops = res.data.data;
            }
        },
        async getProviders(){
            let res = await listProvider('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.providers = res.data.data;
            }
        },
        async getCategory(){
            let res = await listCategory('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.categories = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.name'),
                    type: 'error',
                });
                return;
            }
            if (this.form.keywords.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.keywords'),
                    type: 'error',
                });
                return;
            }
            if (this.form.description.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.description'),
                    type: 'error',
                });
                return;
            }
            if (this.form.meta_description.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.meta-description'),
                    type: 'error',
                });
                return;
            }
            if (this.form.product_category_ids.length <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.category'),
                    type: 'error',
                });
                return;
            }
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('description', this.form.description);
            formData.append('keywords', this.form.keywords);
            formData.append('meta_description', this.form.meta_description);
            formData.append('barcode', this.form.barcode);
            formData.append('sku', this.form.sku);
            formData.append('import_price', this.form.import_price);
            formData.append('show_price', this.form.show_price);
            formData.append('selling_price', this.form.selling_price);
            formData.append('provider_id', this.form.provider_id);
            formData.append('shop_id', this.form.shop_id);
            formData.append('type_id', this.form.type_id);
            formData.append('length', Number(this.form.length));
            formData.append('width', Number(this.form.width));
            formData.append('height', Number(this.form.height));
            formData.append('weight', Number(this.form.weight));
            for(let i = 0; i < this.form.features.length; i++){
                formData.append('features[]', this.form.features[i]);
            }
            for(let j = 0; j < this.form.product_category_ids.length; j++) {
                formData.append('product_category_ids[]', Number(this.form.product_category_ids[j]));
            }
            if (this.form.image != null) {
                formData.append('feature_image', this.form.image);
            }
            if (this.form.images != null) {
                for(let j = 0; j < this.form.images.length; j++) {
                formData.append('images[]', this.form.images[j]);
            }
            }
            formData.append('status', this.form.status);
            formData.append('is_public', this.form.is_public);
            this.$store.state.loading = true;
            let res = await add(formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    type: 'success',
                });
                this.$router.push({ path: '/product' });
            } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-failure')),
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.form = {
                name: '',
                feature_image: '',
                slug: '',
                description: '',
                keywords: '',
                meta_description: '',
                image: null,
                status: 0,
                is_public: 0,
                shop_id: 0,
                product_category_ids: [],
                features: [],
                barcode: '',
                sku: '',
                import_price: 0,
                selling_price: 0,
                show_price: 0,
                provider_id: 0,
                images: [],
                length: 0,
                width: 0,
                height: 0,
                weight: 0,
                type_id: 0,
            };
            this.form.image = null;
            this.form.images = [];
            this.image_previews = [];
        },
        async changeFile(e){
            if(e.raw == null || e.raw.type.indexOf('image/') !== 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.wrong-format')),
                    type: 'error',
                });
                return;
            }
            const isLt2M = e.raw.size / 1024 / 1024 < product_feature_image.size;
            if (!isLt2M) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.file-less-or-equal'))+ product_feature_image.size + 'MB',
                    type: 'error',
                });
                return;
            }
            var tmppath = URL.createObjectURL(e.raw);
            // const img = new Image();
            // img.src = tmppath;
            // await img.decode();
            // if (Math.abs(img.width - product_feature_image.width) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-width-equal'))+ product_feature_image.width + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            // if (Math.abs(img.height - product_feature_image.height) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-height-equal'))+ product_feature_image.height + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            this.form.image = e.raw;
            this.form.feature_image = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        },
        async removeImage(uploadFile, uploadFiles){
            if (uploadFile != null && typeof uploadFile.raw != 'undefined') {
                this.form.images.push(uploadFile.raw);
                var tmppath = URL.createObjectURL(uploadFile.raw);
                this.image_previews.push({
                    name: uploadFile.name,
                    url: tmppath
                });
            }
            console.log(uploadFiles);
        },
        beforeImage(e) {
            console.log(e);
            return true;
        },
        async changeShopId(value) {
            let found = this.shops.filter(e => e.id == value);
            if (found) {
                this.getProviders(found[0].owner_id);
            }
        },
        async changeImage(e){
            if(e.raw == null || e.raw.type.indexOf('image/') !== 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.wrong-format')),
                    type: 'error',
                });
                return;
            }
            const isLt2M = e.raw.size / 1024 / 1024 < product_image.size;
            if (!isLt2M) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.file-less-or-equal'))+ product_image.size + 'MB',
                    type: 'error',
                });
                return false;
            }
            var tmppath = URL.createObjectURL(e.raw);
            // const img = new Image();
            // img.src = tmppath;
            // await img.decode();
            // if (Math.abs(img.width - product_image.width) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-width-equal'))+ product_image.width + 'px',
            //         type: 'error',
            //     });
            //     return false;
            // }
            // if (Math.abs(img.height - product_image.height) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-height-equal'))+ product_image.height + 'px',
            //         type: 'error',
            //     });
            //     return false;
            // }
            this.form.images.push(e.raw);
            this.image_previews.push({
                name: e.name,
                url: tmppath
            });
           // return false; 
        }
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
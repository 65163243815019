const template_imports = [
    {
        INDEX: 1,
        PRODUCT_ID: 2,
        QUANTITY: 100,
        NOTES: 'Ghi chu'
    },
    {
        INDEX: 2,
        PRODUCT_ID: 3,
        QUANTITY: 110,
        NOTES: 'Ghi chu'
    },
    {
        INDEX: 3,
        PRODUCT_ID: 4,
        QUANTITY: 120,
        NOTES: 'Ghi chu'
    },
];
export {template_imports};
import request from '@/utils/request'


function detail(id) {
    return request({
        url: '/manager/customer/'+id.toString(),
        method: 'get',
      })
}
function list(query) {
    return request({
        url: '/manager/customer?'+query.toString(),
        method: 'get',
      })
}

export {detail, list};
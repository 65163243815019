import request from '@/utils/request'

function detail(id) {
    return request({
        url: '/manager/search-result/'+id.toString(),
        method: 'get',
      })
}
function del(id) {
    return request({
        url: '/manager/search-result/'+id.toString(),
        method: 'delete',
      })
}
function list(query) {
    return request({
        url: '/manager/search-result?'+query.toString(),
        method: 'get',
      })
}
function statisticByJob(jobId) {
  return request({
      url: '/manager/search-result/statistic-by-job/'+jobId.toString(),
      method: 'get',
    })
}
export {detail, del, list, statisticByJob};
<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
            <el-form-item style="max-width: 600px" label="Job">
                <el-input v-model="form.admin_google_search_job.keywords" placeholder="" readonly  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.name'))">
                <el-input v-model="form.title" placeholder="title" />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.url'))">
                <el-input v-model="form.url" placeholder="url" />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.from-price'))">
                <el-input v-model="form.from_price" placeholder="0" />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.to-price'))">
                <el-input v-model="form.to_price" placeholder="0" />
            </el-form-item>
            <el-form-item :label="$filters.capitalize($t('title.description'))" style="max-width: 600px">
            </el-form-item>
            <ckeditor :editor="editor" id="ck-description"  v-model="form.descriptions" :config="editorConfig"></ckeditor>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                <router-link :to="{name: 'SearchResultIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Back,
  Refresh,
} from '@element-plus/icons-vue'
</script>
<script>
import { detail } from '@/api/search_result';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "SearchResultEdit",
    data(){
        return {
            form: {
                title: '',
                description: '',
                from_price: '',
                to_price: '',
                url: '',
                admin_google_search_job: {
                    keywords: ''
                },

            },
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            }
        };
    },
    created(){
        this.getDetail();
    },
    methods: {
        async getDetail(){
            this.$store.state.loading = true;
            let res = await detail(this.$route.params.id);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.form = res.data;
            }
        },
        async freshForm(){
            this.getDetail();
        },
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
<template>
    <el-row v-if="show" type="flex" :gutter="4" style="width: 100%; padding: 20px;">
        <el-col :span="24" style="text-align: left;">
            <el-button size="small" class="ms-10" type="danger">
                <download-excel :data="data" :name="name">
                {{ $filters.capitalize($t('action.download'))}}
                <el-icon >
                    <download />
                </el-icon>
                </download-excel>
            </el-button>
        </el-col>
    </el-row>
</template>
<script setup>
  import {
  Download
} from '@element-plus/icons-vue'
</script>
<script>
import { ElButton } from 'element-plus';
export default {
    name: "DownloadElement",
    components: {
        ElButton,
    },
    props: {
        data: {
            type: Array,
        },
        show: {
            type: Boolean
        },
        name: {
            type: String
        },
    }
}
</script>
<style>
.download-div {
    margin-top: 20px;
}
</style>
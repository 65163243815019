<template>
    <el-container >
      <el-main>
      <el-row :gutter="20" style="width: 100%;">
          <el-col :span="24">
              <div class="grid-content ep-bg-purple">
                  <el-form :model="form" style="max-width: 600px; margin: 10px auto;" label-width="auto" label-position="top">
                    <el-form-item style="justify-content: center !important;">
                      <img src="/logo.png" style="margin: 0 auto;" />
                    </el-form-item>
                    <el-form-item label="Thư điện tử">
                      <el-input v-model="form.email" />
                    </el-form-item>
                    <el-form-item label="Mật khẩu">
                      <el-input v-model="form.password" type="password" />
                    </el-form-item>
                    <el-form-item style="justify-content: center !important;">
                      <el-button type="primary" @click="submitLogin" style="width: 100%;">Đăng nhập</el-button>
                    </el-form-item>
                </el-form>
              </div>
          </el-col>
      </el-row>
      </el-main>
</el-container>
  </template>
<script setup>
</script>
<script>
import { login, profile } from '@/api/login';
import { ElMessage } from 'element-plus';
export default {
    name: "LoginPage",
    data(){
      return {
        form: {
          email: '',
          password: ''
        }
      }
    },
    beforeMount(){
      this.$store.state.hideSidebar = true;
      this.$store.state.hideHeader = true;
      this.$store.state.hideBreadScumb = true;
      this.$store.state.hideFooter = true;
    },
    methods: {
      async submitLogin(){
        if (this.form.email == '') {
          ElMessage({
            message: 'Email không được bỏ trống!',
            type: 'error',
          })
          return;
        }
        if (this.form.password == '') {
          ElMessage({
            message: 'Password không được bỏ trống!',
            type: 'error',
          })
          return;
        }
        if (!this.isValidEmail()) {
          ElMessage({
            message: 'Email không được bỏ trống!',
            type: 'error',
          });
          return;
        }
        this.$store.state.loading = true;
        let data = new FormData();
        data.append('email', this.form.email);
        data.append('password', this.form.password);
        let res = await login(data);
        this.$store.state.loading = false;
        if (res != null && res.status == 200 && res.data != null) {
                // this.$store.dispatch('setLoginServer', true);
                this.$store.dispatch('user/setTokenServer', res.data.csrf_token);
                ElMessage({
                  message: 'Login success!',
                  type: 'success',
                });
                let resProfile = await profile();
                if (resProfile != null && resProfile.status == 200 && resProfile.data != null) {
                  console.log(resProfile.data);
                  this.$store.dispatch('user/setUserServer', resProfile.data);
                }
                this.$router.push({ path: '/' });
            } else {
              ElMessage({
                message: 'Đăng nhập thất bại',
                type: 'error',
              });
              return;
            }
      },
      isValidEmail() {
        return /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.email);
      }
    }
};
</script>
<style scoped>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  justify-content: center;
}
</style>

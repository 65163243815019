<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" style="width: 100%;" label-width="auto" label-position="top">
        <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.keywords'))">
            <el-input v-model="form.keywords" placeholder="Từ khóa" width="300px"  />
        </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">{{ $filters.capitalize($t('action.save')) }}</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                <router-link :to="{name: 'SearchEngineIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
} from '@element-plus/icons-vue'
</script>
<script>
import { add } from '@/api/search_engine';
import { ElMessage } from 'element-plus';
export default {
    name: "SearchEngineCreate",
    data(){
        return {
            form: {
                keywords: ''
            },
            statusList: [
                {
                    val: 0,
                    label: 'no-active'
                },
                {
                    val: 1,
                    label: 'active'
                }
            ],
        };
    },
    created(){
    },
    methods: {
        async submitForm(){
            if (this.form.keywords.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.keywords'),
                    type: 'error',
                });
                return;
            }
            this.$store.state.loading = true;
            let formData = new FormData();
            formData.append('keywords', this.form.keywords);
            formData.append('meta_description', this.form.meta_description);
            let res = await add(formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    type: 'success',
                });
                this.$router.push({ path: '/search-engine' });
            } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-failure')),
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.form = {
                keywords: ''
            };
        },
    }
}
</script>
<style>
</style>
<template>
    <el-row type="flex" :gutter="4" style="width: 100%; padding-left: 10px; margin-top: 20px;">
      <el-col :lg="24" :xl="24" :sm="24" :md="24" :xs="24">
        <el-card class="box-card" >
          <template #header>
            <div class="card-header">
              <span>{{ $filters.capitalize($t('menu.statistic')) }} : {{ job.keywords }}</span>
            </div>
          </template>
          <Responsive class="w-full">
            <template #main="{ width }">
            <Chart
                :size="{ width: width, height: 660 }"
                :data="products"
                :margin="margin"
                >
                <template #layers>
                    <Grid strokeDasharray="2,2" />
                    <Bar :data-keys="['label', 'qty']" :barStyle="{ fill: '#0096c7' }" :maxWidth="20" />
                </template>
                <template #widgets>
                    <Tooltip
                        borderColor="#48CAE4"
                        :config="{
                            qty: { color: '#0096c7' },
                        }"
                    />
                    </template>
                </Chart>
            </template>
            </Responsive>
        </el-card>
      </el-col>
    </el-row>
  </template>
  <script setup>
  import { Chart, Grid, Bar, Tooltip, Responsive } from 'vue3-charts'
</script>
  <script>
  import { statisticByJob } from '@/api/search_result';
  import { detail } from '@/api/search_engine';
  export default {
      name: "StatisticJobIdPage",
      components: {
},
      data(){
        return {
          products: [],
          job: {},
          margin: {
            left: 0,
            top: 10,
            right: 0,
            bottom: 10
          }
        }
      },
      beforeMount(){
        this.$store.state.hideSidebar = false;
        this.$store.state.hideHeader = false;
        this.$store.state.hideBreadScumb = false;
        this.$store.state.hideFooter = false;
      },
      created(){
        var jobId = this.$route.params.jobId;
        this.getLists(jobId);
        this.getJob(jobId);
      },
      methods: {
        async getLists(jobId){
          this.$store.state.loading = true;
          let res = await statisticByJob(jobId);
          this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.products = res.data;
            }
        },
        async getJob(jobId) {
            this.$store.state.loading = true;
            let res = await detail(jobId);
            this.$store.state.loading = false;
                if (res != null && res.status == 200 && res.data != null) {
                    this.job = res.data;
                }
        }
      },
      watch: {
        '$route'(to, from){
            console.log(from);
            //this.getLists(query);
            console.log(to);
        }
    },
  };
  </script>
  <style scoped>
  .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  .item {
    margin-top: 10px;
    margin-right: 0px;
  }
  </style>
<template>
  <el-container class="layout-container-demo" v-loading="loading">
    <el-aside class="aside-menu" v-show="!this.$store.state.hideSidebar">
      <el-scrollbar>
        <left-menu />
      </el-scrollbar>
    </el-aside>
    <el-container>
      <el-header style="text-align: left; font-size: 12px" v-show="!this.$store.state.hideHeader">
        <header-nav />
      </el-header>
      <bread-scumb v-show="!this.$store.state.hideBreadScumb" />
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
  <el-container v-show="!this.$store.state.hideFooter">
    <footer-element/>
  </el-container>
</template>
<script>
import LeftMenu from './components/LeftMenu.vue'
import FooterElement from './components/FooterElement.vue'
import BreadScumb from './components/BreadScumb.vue'
import HeaderNav from './components/HeaderNav.vue'
export default {
  name: 'App',
  components: {
        LeftMenu,
        FooterElement,
        BreadScumb,
        HeaderNav
  },
  data(){
    return {
      loading: true
    }
  },
  async created(){
    console.log(this.$store.getters.token);
    //if (typeof this.$store.getters.token == 'undefined') {
      await this.$store.dispatch('user/initCsrf');
    //}
  },
  watch: {
    '$store.state.loading': {
        handler(newLoading){
            this.loading = newLoading;
        },
        immediate: true,
        deep: true
    },
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 8px;
}
</style>
<style scoped>
.layout-container-demo .el-header {
  position: relative;
  background-color: #545c64;
  color: white;
}
.layout-container-demo .el-aside {
  color: var(--el-text-color-primary);
  background: #545c64;
}
.layout-container-demo .el-menu {
  border-right: none;
}
.layout-container-demo .el-main {
  padding: 0;
}
.layout-container-demo .toolbar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: 20px;
}
.aside-menu {
    width: 250px;
  }
@media only screen and (max-width: 768px) {
  .aside-menu {
    width: 60px;
  }
}
</style>
<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
            <el-row type="flex" :gutter="4" style="width: 100%;">
                <el-col :span="24" style="text-align: left;">
                    <el-button size="small" class="ms-10" type="warning">
                        <el-icon style="margin-right: 5px;">
                            <download />
                        </el-icon>
                        <download-excel :data="template_imports" :name="'import_template.xlsx'">
                        {{ $filters.capitalize($t('action.download'))}} {{ $t('title.format')}} {{ $t('menu.import-stock')}}
                        
                        </download-excel>
                    </el-button>
                </el-col>
            </el-row>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-upload
                ref="upload"
                class="upload-demo"
                :limit="1"
                :on-change="changeFile"
                :before-upload="beforeUpload"
                auto-upload="false"
                accept="application/vnd.ms-excel, application/msexcel, application/x-msexcel, application/x-ms-excel, application/x-excel, application/x-dos_ms_excel, application/xls, application/x-xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
            <template #trigger>
                <el-button size="small" class="ms-10" type="primary">
                    <el-icon style="margin-right: 5px;">
                        <upload />
                    </el-icon>
                    {{ $filters.capitalize($t('action.select-file')) }}
                </el-button>
            </template>
            </el-upload>
        </el-row>
        <el-form-item style="max-width: 600px" label="Shop">
                <el-select v-model="form.shop_id" @change="changeShopId">
                <el-option
                    v-for="item in shops"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.notes'))">
                <el-input v-model="form.notes" placeholder="notes" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('import.import-date'))">
                <el-date-picker v-model="form.import_date" type="datetime" format="YYYY-MM-DD HH:mm:ss" width="300px"  />
            </el-form-item>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" >
                <el-table :data="form.products" style="overflow-x: scroll;" :row-class-name="tableRowClassName">
                    <el-table-column label="#" width="50">
                        <template #default="scope">
                            {{ scope.$index }}
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('menu.product'))" width="200">
                        <template #default="scope">
                            <el-select v-model="scope.row.id">
                                <el-option
                                    v-for="item in products"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                />
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.quantity'))" width="200">
                        <template #default="scope">
                            <el-input-number step="1" min="0" v-model="scope.row.qty" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.notes'))">
                        <template #default="scope">
                            <el-input v-model="scope.row.notes" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.operators'))" width="100">
                        <template #default="scope">
                            <el-button size="small" type="danger" @click="removeProduct(scope.$index)" :icon="Delete">{{ $filters.capitalize($t('action.delete')) }}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">{{ $filters.capitalize($t('action.save')) }}</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                <router-link :to="{name: 'ExportStockIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>
import {
  Select,
  Back,
  Refresh,
  Download,
  Upload
} from '@element-plus/icons-vue'
</script>
<script>
import { list as listShops } from '@/api/shop';
import {list as listProducts} from '@/api/product';
import { importIm } from '@/api/import_stock';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import readXlsxFile from 'read-excel-file';
import { import_template } from '@/constants/templates';
import { template_imports } from '@/templates/imports';
// import { product_feature_image, product_image, distance_tolerance } from '@/constants/configs';
export default {
    name: "ExportUpload",
    data(){
        return {
            dialogVisible: false,
            form: {
                shop_id: 0,
                products: [],
                notes: '',
                import_date: (new Date()),
                status: 1,
            },
            statusList: [
                {
                    val: 0,
                    label: 'no-active'
                },
                {
                    val: 1,
                    label: 'active'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            shops: [],
            products: [],
            template_imports: template_imports
        };
    },
    created(){
        this.getShops();
    },
    methods: {
        async getShops(){
            let res = await listShops('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.shops = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.products.length <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('menu.product'),
                    type: 'error',
                });
                return;
            }
            if (this.form.shop_id <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('menu.shop'),
                    type: 'error',
                });
                return;
            }
            for (let i = 0; i < this.form.products.length; i++) {
                if (this.form.products[i].id == null || this.form.products[i].id <= 0) {
                    ElMessage({
                        message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('menu.product'),
                        type: 'error',
                    });
                    return;
                }
            }
            
            let formData = new FormData();
            formData.append('shop_id', this.form.shop_id);
            formData.append('notes', this.form.notes);
            formData.append('import_date', this.form.import_date.getTime()/1000);
            formData.append('status', this.form.status);
            if (this.form.products.length > 0) {
                for(let i = 0; i < this.form.products.length; i++) {
                    formData.append('products['+i+'][id]', Number(this.form.products[i].id));
                    formData.append('products['+i+'][qty]', Number(this.form.products[i].qty)); 
                    formData.append('products['+i+'][notes]', this.form.products[i].notes); 
                }
            }
            this.$store.state.loading = true;
            let res = await importIm(formData);
            this.$store.state.loading = false;
            console.log(res);
            if (res != null && res.status == 200) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    type: 'success',
                });
                this.$router.push({ path: '/import-stock' });
            } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-failure')),
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.form = {
                shop_id: 0,
                products: [],
                notes: '',
                import_date: (new Date()),
                status: 1,
            };
        },
        async changeFile(e){
            if(e.raw == null || (e.raw.type.indexOf('application/vnd.ms-excel') !== 0 
            && e.raw.type.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') !== 0
            && e.raw.type.indexOf('application/msexcel') !== 0
            && e.raw.type.indexOf('application/x-msexcel') !== 0
            && e.raw.type.indexOf('application/x-ms-excel') !== 0
            && e.raw.type.indexOf('application/x-excel') !== 0
            && e.raw.type.indexOf('application/x-dos_ms_excel') !== 0
            && e.raw.type.indexOf('application/xls') !== 0
            && e.raw.type.indexOf('application/x-xls') !== 0
            )) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.wrong-format')),
                    type: 'error',
                });
                return;
            }
            let data = await readXlsxFile(e.raw, { sheet: 'Sheet1', schema: import_template });
            if (data.errors == null || data.errors.length == 0) {
                this.form.products = data.rows;
            }
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        },
        tableRowClassName(row){
            if (row.rowIndex % 2 == 1) {
                return 'warning-row';
            } else 
                return 'success-row';
        },
        removeProduct(index) {
            this.form.products.splice(index, 1);
        },
        changeShopId(value){
            this.getProducts(value);
        },
        async getProducts(shopId) {
            let res = await listProducts('page=1&pagesize=10000&shop_id='+shopId.toString());
            console.log(res);
            if (res != null && res.status == 200 && res.data != null) {
                this.products = res.data.data;
            }
        },
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
<style>
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-9);
}
.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
</style>
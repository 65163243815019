const provider_template = {
  'INDEX': {
    // JSON object property name.
    prop: 'stt',
    type: Number
  },
  'NAME': {
    // JSON object property name.
    prop: 'name',
    type: String,
    required: true
  },
  'DESCRIPTION': {
    prop: 'description',
    type: String,
    required: true
  },
  'ADDRESS': {
    prop: 'address',
    type: String,
    required: true
  },
  'PHONE': {
    prop: 'phone',
    type: String,
    required: true
  },
  'WEBSITE': {
    prop: 'website',
    type: String,
    required: true
  },
  'FACEBOOK': {
    prop: 'facebook',
    type: String,
    required: true
  },
  'ZALO': {
    prop: 'zalo',
    type: String,
    required: true
  },
  'TIKTOK': {
    prop: 'tiktok',
    type: String,
    required: true
  },
  'TWITTER': {
    prop: 'twitter',
    type: String,
    required: true
  },
  'STATUS': {
    prop: 'status',
    type: Number,
    oneOf: [
      0,
      1,
    ]
  }
};

const product_template = {
  'INDEX': {
    // JSON object property name.
    prop: 'stt',
    type: Number
  },
  'PROVIDER_ID': {
    // JSON object property name.
    prop: 'provider_id',
    type: (value) => {
      const str = parseInt(value);
      if (!str) {
        return 0;
      }
      return str;
    },
    required: false
  },
  'NAME': {
    // JSON object property name.
    prop: 'name',
    type: String,
    required: true
  },
  'BARCODE': {
    // JSON object property name.
    prop: 'barcode',
    type: String,
    required: false
  },
  'SKU': {
    // JSON object property name.
    prop: 'sku',
    type: String,
    required: false
  },
  'KEYWORDS': {
    prop: 'keywords',
    type: String,
    required: true
  },
  'META_DESCRIPTION': {
    prop: 'meta_description',
    type: String,
    required: true
  },
  'DESCRIPTION': {
    prop: 'description',
    type: String,
    required: true
  },
  'FEATURES': {
    prop: 'features',
    type: (value) => {
      const str = value.toString();
      if (!str) {
        throw new Error('invalid')
      }
      return str.split(';').map(i=> String(i));
    },
    required: true
  },
  'PRODUCT_CATEGORY_IDS': {
    prop: 'product_category_ids',
    type: (value) => {
      const str = value.toString();
      if (!str) {
        throw new Error('invalid')
      }
      return str.split(';').map(i=>Number(i));
    },
    required: true
  },
  'IMPORT_PRICE': {
    // JSON object property name.
    prop: 'import_price',
    type: Number
  },
  'SELLING_PRICE': {
    // JSON object property name.
    prop: 'selling_price',
    type: Number
  },
  'SHOW_PRICE': {
    // JSON object property name.
    prop: 'show_price',
    type: Number
  },
  'STATUS': {
    prop: 'status',
    type: Number,
    oneOf: [
      0,
      1,
    ]
  },
  'IS_PUBLIC': {
    prop: 'is_public',
    type: Number,
    oneOf: [
      0,
      1,
    ]
  }
};
const import_template = {
  'INDEX': {
    // JSON object property name.
    prop: 'stt',
    type: Number
  },
  'PRODUCT_ID': {
    prop: 'id',
    type: Number,
    required: true
  },
  'QUANTITY': {
    prop: 'qty',
    type: Number,
    required: true
  },
  'NOTES': {
    prop: 'notes',
    type: String,
    required: true
  },
};
const export_template = {
  'INDEX': {
    // JSON object property name.
    prop: 'stt',
    type: Number
  },
  'PRODUCT_ID': {
    prop: 'id',
    type: Number,
    required: true
  },
  'QUANTITY': {
    prop: 'qty',
    type: Number,
    required: true
  },
  'NOTES': {
    prop: 'notes',
    type: String,
    required: true
  },
};
export {product_template, provider_template, import_template, export_template};
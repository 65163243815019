import request from '@/utils/request'

function login(data) {
  return request({
    url: '/manager/login',
    method: 'post',
    data
  })
}
function getCsrf(){
  return request({
    url: '/appconfig',
    method: 'get',
  })
}
function profile(){
  return request({
    url: '/profile',
    method: 'get',
  })
}
function editProfile(data) {
  return request({
    url: '/profile',
    method: 'post',
    data
  })
}
function uploadAvatar(data) {
  return request({
    url: '/profile/avatar',
    method: 'post',
    data
  })
}
function logout() {
  return request({
      url: '/logout',
      method: 'get'
  });
}
export {getCsrf, login, profile, editProfile, logout, uploadAvatar};
<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
            <el-text class="mx-1" type="danger">{{ $filters.capitalize($t('title.image-have-size')) + costtype_image.width +"x"+costtype_image.height+"px" }}</el-text>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                    auto-upload="false"
                    accept="image/jpg, image/jpeg, image/png"
                >

                <img v-if="form.feature_image" :src="form.feature_image" class="avatar" style="max-width: 600px;" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.name'))">
                <el-input v-model="form.name" placeholder="name"  />
            </el-form-item>
            <el-form-item :label="$filters.capitalize($t('title.description'))" style="max-width: 600px">
            </el-form-item>
            <ckeditor :editor="editor" id="ck-description"  v-model="form.description" :config="editorConfig"></ckeditor>
            <el-form-item style="max-width: 600px"  :label="$filters.capitalize($t('title.status'))">
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="$filters.capitalize($t('title.'+item.label))"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('menu.shop'))">
                <el-select v-model="form.shop_id" >
                <el-option
                    v-for="(item, index) in shops"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">{{ $filters.capitalize($t('action.save')) }}</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                <router-link :to="{name: 'CostTypeIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus
} from '@element-plus/icons-vue'
</script>
<script>
import { add } from '@/api/cost_type';
import { list as listShops } from '@/api/shop';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { costtype_image } from '@/constants/configs';
export default {
    name: "CostTypeCreate",
    data(){
        return {
            form: {
                name: '',
                feature_image: '',
                description: '',
                image: null,
                status: 0,
                shop_id: 0
            },
            statusList: [
                {
                    val: 0,
                    label: 'no-active'
                },
                {
                    val: 1,
                    label: 'active'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            shops: []
        };
    },
    created(){
        this.getShops();
    },
    methods: {
        async getShops(){
            let res = await listShops('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.shops = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.name'),
                    type: 'error',
                });
                return;
            }
            if (this.form.description.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.description'),
                    type: 'error',
                });
                return;
            }
            if (this.form.image == null) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.feature-image'),
                    type: 'error',
                });
                return;
            }
            if (this.form.shop_id <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('menu.shop'),
                    type: 'error',
                });
                return;
            }
            this.$store.state.loading = true;
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('description', this.form.description);
            formData.append('status', this.form.status);
            formData.append('shop_id', this.form.shop_id);
            if (this.form.image != null) {
                formData.append('feature_image', this.form.image);
            }
            let res = await add(formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    type: 'success',
                });
                this.$router.push({ path: '/cost-type' });
            } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-failure')),
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.form = {
                name: '',
                feature_image: '',
                description: '',
                image: null,
                status: 0,
                shop_id: 0
            };
        },
        async changeFile(e){
            if(e.raw == null || e.raw.type.indexOf('image/') !== 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.wrong-format')),
                    type: 'error',
                });
                return;
            }
            const isLt2M = e.raw.size / 1024 / 1024 < costtype_image.size;
            if (!isLt2M) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.file-less-or-equal'))+ costtype_image.size + 'MB',
                    type: 'error',
                });
                return;
            }
            var tmppath = URL.createObjectURL(e.raw);
            const img = new Image();
            img.src = tmppath;
            // await img.decode();
            // if (Math.abs(img.width - costtype_image.width) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-width-equal'))+ costtype_image.width + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            // if (Math.abs(img.height - costtype_image.height) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-height-equal'))+ costtype_image.height + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            this.form.image = e.raw;
            this.form.feature_image = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        }
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
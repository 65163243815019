import request from '@/utils/request'

function add(data) {
  return request({
    url: '/manager/shop',
    method: 'post',
    data
  })
}
function detail(id) {
    return request({
        url: '/manager/shop/'+id.toString(),
        method: 'get',
      })
}
function edit(id, data) {
    return request({
        url: '/manager/shop/'+id.toString(),
        method: 'post',
        data
      })
}
function del(id) {
    return request({
        url: '/manager/shop/'+id.toString(),
        method: 'delete',
      })
}
function list(query) {
    return request({
        url: '/manager/shop?'+query.toString(),
        method: 'get',
      })
}
export {add, detail, edit, del, list};
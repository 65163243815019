<template>
    <search-form 
    :change-keyword="changeKeywords" 
    :change-from-date="changeFromDate" 
    :change-to-date="changeToDate" 
    :change-user="changeUser"
    :change-product="changeProduct"
    :on-search="onSearch"
    :on-refresh="onRefresh"
    :on-export-data="onExport"
    :products="products"
    :users="users"
    />
    <download-element :data="admins" :show="true" :name="'orders.xlsx'"/>
    <el-table :data="admins" style="padding-left: 20px;">
      <el-table-column label="#" width="50">
        <template #default="scope">
            {{ scope.$index }}
        </template>
      </el-table-column>
      <el-table-column prop="code" :label="$filters.capitalize($t('export.order-code'))" />
      <el-table-column prop="user.email" :label="$filters.capitalize($t('order.customer'))" />
      <el-table-column prop="shop.name" label="Shop" />
      <el-table-column prop="notes" :label="$filters.capitalize($t('title.notes'))" />
      <el-table-column prop="total" :label="$filters.capitalize($t('cost.amount'))" />
      <el-table-column label="Sản phẩm" width="400">
        <template #default="scope">
            <el-badge v-for="(item, index) in scope.row.order_details" v-bind:key="index" :value="item.qty" class="item" type="warning">
              <el-button size="small">{{ item.product.name }}</el-button>
            </el-badge>
        </template>
      </el-table-column>
      <el-table-column :label="$filters.capitalize($t('title.status'))">
        <template #default="scope">
          <el-tag class="ml-2" :type="scope.row.status == 1 ? 'success' : 'danger'">
            {{ showTextStatus(scope.row.status) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$filters.capitalize($t('order.payment-method'))">
        <template #default="scope">
          <el-tag class="ml-2" :type="scope.row.payment_method == 1 ? 'success' : 'danger'">
            {{ showTextPaymentMethod(scope.row.payment_method) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$filters.capitalize($t('order.is-paid'))">
        <template #default="scope">
          <el-tag class="ml-2" :type="scope.row.payment_status == 1 ? 'success' : 'danger'">
            {{ showTextPaymentStatus(scope.row.payment_status) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$filters.capitalize($t('title.created-time'))">
        <template #default="scope">
          {{ (new Date(scope.row.created_at)).toLocaleString() }}
        </template>
      </el-table-column>
      <el-table-column :label="$filters.capitalize($t('order.updated-time'))">
        <template #default="scope">
          {{ (new Date(scope.row.updated_at)).toLocaleString() }}
        </template>
      </el-table-column>
      <el-table-column :className="'group-buttons'">
        <template #default="scope">
          <el-button size="small" type="danger" :icon="Delete" @click="openDelete(scope.row.id)">{{ $filters.capitalize($t('action.delete')) }}</el-button>
          <router-link :to="{name: 'OrderEdit', params: {id: scope.row.id}}">
            <el-button size="small" class="ms-10" type="success" :icon="Edit">{{ $filters.capitalize($t('action.detail')) }}</el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination-element 
    :total="search.total" 
    :page-size="search.pagesize" 
    @change="changePage" 
    :prev-click="prevPage" 
    :next-click="nextPage"
    :page-sizes="pagesizes"
    @size-change="handleSizeChange"
    />
  </template>
  <script setup>
  import {
  Edit,
  Delete
} from '@element-plus/icons-vue'
</script>
  <script>
  import { list, del, config } from '@/api/order';
  import { list as listCustomers } from '@/api/customer';
  import { list as listProducts } from '@/api/product';
  import SearchForm from './components/SearchForm.vue';
  import { ElButton, ElMessage, ElMessageBox } from 'element-plus';
  import PaginationElement from '@/components/PaginationElement.vue';
  import DownloadElement from '@/components/DownloadElement.vue';
  export default {
      name: "OrderPage",
      components: {
    SearchForm,
    ElButton,
    DownloadElement
},
      data(){
        return {
          delete_id: 0,
          admins: [],
          search: {
            keywords: '',
            from_date: 0,
            to_date: 0,
            user_id: 0,
            product_id: 0,
            total: 0,
            page: 1,
            pagesize: 5,
          },
          show: true,
          pagesizes: [10,20,50,100],
          products: [],
          users: [],
          payment_methods: [],
          ship_methods: [],
          payment_status: []
        }
      },
      beforeMount(){
        this.$store.state.hideSidebar = false;
        this.$store.state.hideHeader = false;
        this.$store.state.hideBreadScumb = false;
        this.$store.state.hideFooter = false;
      },
      created(){
        this.getConfig();
        var params = this.$router.currentRoute.value.fullPath.split('?');
        var query = '';
        if (params.length > 1) {
          query = params.slice(-1);
        }
        this.getLists(query);
        var currentQuery = this.$router.currentRoute.value.query;
        if (currentQuery.keywords != undefined) {
          this.search.keywords = currentQuery.keywords;
        }
        if (currentQuery.user_id != undefined) {
          this.search.user_id = currentQuery.user_id;
        }
        if (currentQuery.product_id != undefined) {
          this.search.product_id = currentQuery.product_id;
        }
        if (currentQuery.from_date != undefined) {
          this.search.from_date = currentQuery.from_date;
        }
        if (currentQuery.to_date != undefined) {
          this.search.to_date = currentQuery.to_date;
        }
        if (currentQuery.page != undefined) {
          this.search.page = currentQuery.page;
        }
        if (currentQuery.pagesize != undefined) {
          this.search.pagesize = currentQuery.pagesize;
        }
        this.getProducts();
        this.getCustomers();
      },
      methods: {
        showTextPaymentMethod(id){
          let text = '';
          let found = this.payment_methods.filter(el => el.id == id);
          if (found) {
            text = found[0].name;
          }
          return text;
        },
        showTextStatus(id){
          let text = '';
          let found = this.statusList.filter(el => el.id == id);
          if (found) {
            text = found[0].name;
          }
          return text;
        },
        showTextPaymentStatus(id){
          let text = '';
          let found = this.payment_status.filter(el => el.id == id);
          if (found) {
            text = found[0].name;
          }
          return text;
        },
        async getConfig() {
            let res = await config();
            if (res != null && res.status == 200 && res.data != null) {
                if (res.data.list_payment_methods != null) {
                    this.payment_methods = res.data.list_payment_methods;
                    
                }
                if (res.data.list_ship_methods != null) {
                    this.ship_methods = res.data.list_ship_methods;
                }
                if (res.data.list_payment_status != null) {
                    this.payment_status = res.data.list_payment_status;
                }
                if (res.data.list_order_status != null) {
                    this.statusList = res.data.list_order_status;
                }
            }
        },
        async getProducts(){
          this.$store.state.loading = true;
          let res = await listProducts('pagesize=1000');
          this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.products = res.data.data;
            }
        },
        async getCustomers(){
          this.$store.state.loading = true;
          let res = await listCustomers('pagesize=1000');
          this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.users = res.data.data;
            }
        },
        async getLists(query){
          this.$store.state.loading = true;
          let res = await list(query);
          this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.admins = res.data.data;
                this.search.total = res.data.total;
                this.search.page = res.data.page;
                this.search.pagesize = res.data.pagesize;
            }
        },
        changePage(value){
            if (this.search.page != value) {
                this.search.page = value;
                this.$router.push({ path: '/order', query: this.search });
            }
        },
        nextPage(){
            this.search.page = Number(this.search.page)+1;
            this.$router.push({ path: '/order', query: this.search });
        },
        prevPage(){
            this.search.page = Number(this.search.page)-1;
            this.$router.push({ path: '/order', query: this.search });
        },
        async onSearch(){
          if (this.search.keywords.trim() != '' 
          || this.search.role > 0 
          || this.search.from_date > 0 
          || this.search.to_date > 0
          || this.search.user_id > 0
          || this.search.product_id > 0) {
              this.$router.push({ path: '/order', query: this.search });
          }
        },
        async onRefresh(){
          this.search.keywords = '';
          this.search.role = 0;
          this.search.from_date = 0;
          this.search.to_date = 0;
          this.$router.push({ path: '/order' });
        },
        async onExport(){

        },
        changeKeywords(e) {
            this.search.keywords = e;
        },
        changeFromDate(e) {
          let from_date = new Date(e);
          if (from_date != null) {
            this.search.from_date = from_date.getTime()/1000;
          }
        },
        changeToDate(e) {
          let to_date = new Date(e);
          if (to_date != null) {
            this.search.to_date = to_date.getTime()/1000;
          }
        },
        changeUser(e) {
          this.search.user_id = Number(e);
        },
        openDelete(id){
          ElMessageBox.confirm(
            this.$filters.capitalize(this.$t('title.do-you-wan-to-delete-this-item')),
            this.$filters.capitalize(this.$t('title.danger')),
            {
              confirmButtonText: this.$filters.capitalize(this.$t('action.ok')),
              cancelButtonText: this.$filters.capitalize(this.$t('action.cancel')),
              type: 'danger',
              draggable: true
            }
          ).then(() => {
            this.agreeDelete(id);
          }).catch(() => {
            console.log('no');
          });
        },
        async agreeDelete(id){
          if (id > 0){
            this.$store.state.loading = true;
              let res = await del(id);
              this.$store.state.loading = false;
              if (res != null && res.status == 200) {
                  for(var i = 0; i < this.admins.length; i++) {
                      if (this.admins[i].id == id) {
                          this.admins.splice(i, 1);
                          break;
                      }
                  }
                  ElMessage({
                      message: this.$filters.capitalize(this.$t('title.delete-success')),
                      type: 'success',
                    });
              } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.delete-failure')),
                    type: 'error',
                  });
              }
          }
        },
        cancelDelete(id){
          console.log(id);
        },
        handleSizeChange(val){
          this.search.pagesize = Number(val);
          this.$router.push({ path: '/order', query: this.search });
        },
        changeProduct(val){
          this.search.product_id = Number(val);
        }
      },
      watch: {
        '$route'(to, from){
            console.log(from);
            var params = to.fullPath.split('?');
            var query = '';
            if (params.length > 1) {
                query = params.slice(-1);
            }
            this.getLists(query);
        }
    },
  };
  </script>
  <style scoped>
  .item {
    margin-top: 10px;
    margin-right: 0px;
  }
  </style>
  <style scoped>
  @media screen and (max-width: 480px) {
    .group-buttons button {
      margin-bottom: 10px;
      margin-left: 0px !important;
    }
  }
  @media screen and (min-width: 961px) {
    .group-buttons button {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
  </style>
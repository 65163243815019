<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('menu.shop'))">
                <el-select v-model="form.shop_id" @change="changeShop">
                <el-option
                    v-for="(item, index) in shops"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('cost.cost-type'))">
                <el-select v-model="form.cost_type_id">
                <el-option
                    v-for="(item, index) in cost_types"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
            <el-form-item style="max-width: 600px"  :label="$filters.capitalize($t('title.name'))">
                <el-input v-model="form.name" placeholder="name"   />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('cost.amount'))">
                <el-input-number min="0" v-model="form.amount" placeholder="0"   />
            </el-form-item>
            <el-form-item style="max-width: 600px"  :label="$filters.capitalize($t('cost.tax-rate'))">
                <el-input-number step="0.1" min="0" v-model="form.tax_rate" placeholder="0"   />
            </el-form-item>
            <el-form-item style="max-width: 600px"  :label="$filters.capitalize($t('cost.bill-code'))">
                <el-input v-model="form.bill_code" placeholder="123456"   />
            </el-form-item>
            <el-form-item style="max-width: 600px"  :label="$filters.capitalize($t('cost.bill-link'))">
                <el-input v-model="form.bill_link" placeholder="http://"   />
            </el-form-item>
            <el-form-item :label="$filters.capitalize($t('cost.bill-image'))" style="max-width: 600px">
            </el-form-item>
            <el-text class="mx-1" type="danger">{{ $filters.capitalize($t('title.image-have-size')) + cost_bill_image.width +"x"+cost_bill_image.height+"px" }}</el-text>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                    auto-upload="false"
                    accept="image/jpg, image/jpeg, image/png, application/pdf"
                >

                <img v-if="form.bill_image" :src="form.bill_image" class="avatar" style="max-width: 600px;" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
            <el-form-item :label="$filters.capitalize($t('title.notes'))" style="max-width: 600px">
            </el-form-item>
            <ckeditor :editor="editor" id="ck-notes"  v-model="form.notes" :config="editorConfig"></ckeditor>
            <el-form-item style="max-width: 600px"  :label="$filters.capitalize($t('cost.spend-time'))">
                <el-date-picker v-model="form.spend_date" type="datetime" format="YYYY-MM-DD HH:mm:ss"  />
            </el-form-item>
            <el-form-item style="max-width: 600px"  :label="$filters.capitalize($t('title.status'))">
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="$filters.capitalize($t('title.'+item.label))"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">{{ $filters.capitalize($t('action.save')) }}</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                <router-link :to="{name: 'CostIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus
} from '@element-plus/icons-vue'
</script>
<script>
import { add } from '@/api/cost';
import { list as listShops } from '@/api/shop';
import { list as listCostTypes } from '@/api/cost_type';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { cost_bill_image } from '@/constants/configs';
export default {
    name: "CostCreate",
    data(){
        return {
            form: {
                name: '',
                amount: 0,
                tax_rate: 0,
                bill_link: '',
                bill_code: '',
                bill_image: '',
                notes: '',
                image: null,
                status: 0,
                shop_id: 0,
                cost_type_id: 0,
                spend_date: (new Date())
            },
            statusList: [
                {
                    val: 0,
                    label: 'no-active'
                },
                {
                    val: 1,
                    label: 'active'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            shops: [],
            cost_types: []
        };
    },
    created(){
        this.getShops();
    },
    methods: {
        async getShops(){
            let res = await listShops('page=1&pagesize=1000');
            if (res != null && res.status == 200 && res.data != null) {
                this.shops = res.data.data;
            }
        },
        async getCostTypes(shopId){
            let res = await listCostTypes('page=1&pagesize=1000&shop_id='+shopId.toString());
            if (res != null && res.status == 200 && res.data != null) {
                this.cost_types = res.data.data;
            }
        },
        async submitForm(){
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.name'),
                    type: 'error',
                });
                return;
            }
            if (this.form.shop_id <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('menu.shop'),
                    type: 'error',
                });
                return;
            }
            if (this.form.cost_type_id <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('menu.cost-type'),
                    type: 'error',
                });
                return;
            }
            
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('amount', Number(this.form.amount));
            formData.append('tax_rate', Number(this.form.tax_rate));
            formData.append('bill_code', this.form.bill_code);
            formData.append('bill_link', this.form.bill_link);
            formData.append('notes', this.form.notes);
            formData.append('status', Number(this.form.status));
            formData.append('shop_id', Number(this.form.shop_id));
            formData.append('cost_type_id', Number(this.form.cost_type_id));
            if (this.form.image != null) {
                formData.append('bill_image', this.form.image);
            }
            formData.append('spend_date', this.form.spend_date.getTime()/1000);
            this.$store.state.loading = true;
            let res = await add(formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    type: 'success',
                });
                this.$router.push({ path: '/cost' });
            } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-failure')),
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.form = {
                name: '',
                amount: 0,
                tax_rate: 0,
                bill_link: '',
                bill_code: '',
                bill_image: '',
                notes: '',
                image: null,
                status: 0,
                shop_id: 0,
                cost_type_id: 0,
                spend_date: (new Date())
            };
            this.cost_types = [];
        },
        async changeFile(e){
            if(e.raw == null || e.raw.type.indexOf('image/') !== 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.wrong-format')),
                    type: 'error',
                });
                return;
            }
            const isLt2M = e.raw.size / 1024 / 1024 < cost_bill_image.size;
            if (!isLt2M) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.file-less-or-equal'))+ cost_bill_image.size + 'MB',
                    type: 'error',
                });
                return;
            }
            var tmppath = URL.createObjectURL(e.raw);
            const img = new Image();
            img.src = tmppath;
            // await img.decode();
            // if (Math.abs(img.width - cost_bill_image.width) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-width-equal'))+ cost_bill_image.width + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            // if (Math.abs(img.height - cost_bill_image.height) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-height-equal'))+ cost_bill_image.height + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            this.form.image = e.raw;
            this.form.bill_image = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        },
        changeShop(val) {
            this.getCostTypes(val);
        }
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
const Role = {
	RoleTypeUser      : 1,
	RoleTypeShopEditor : 2,
	RoleTypeShopAdmin  : 3,
  };
const RoleNames = [
	{
		id: Role.RoleTypeUser,
		name: 'User'
	},
	{
		id: Role.RoleTypeShopEditor,
		name: 'ShopEditor'
	},
	{
		id: Role.RoleTypeShopAdmin,
		name: 'ShopAdmin'
	},
];
export {Role, RoleNames};
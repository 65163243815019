import request from '@/utils/request'


function sales(query) {
    return request({
        url: '/manager/statistic/sales?'+query.toString(),
        method: 'get',
      })
}
function list(query) {
    return request({
        url: '/manager/statistic?'+query.toString(),
        method: 'get',
      })
}

export {sales, list};
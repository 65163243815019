<template>
    <el-row class="tac" width="100%">
      <el-col width="100%">
        <div style="width: 100%; background-color: #fff; height: 60px;">
          <img src="/logo.png" height="60px" style="margin-left: 0;"/>
        </div>
        <el-menu
          active-text-color="#ffd04b"
          background-color="#545c64"
          class="el-menu-vertical-demo"
          :default-active="menuActive"
          text-color="#fff"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-menu-item index="1" :title="$filters.capitalize($t('menu.dashboard'))">
            <el-link href="/" class="link-item" :class="menuActive == 1 ? 'active' : ''">
                <el-icon><icon-menu /></el-icon>
                <span class="hidden-sm-and-down">{{ $filters.capitalize($t('menu.dashboard')) }}</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="2" :title="$filters.capitalize($t('menu.shop'))">
            <el-link href="/shop" class="link-item"  :class="menuActive == 2 ? 'active' : ''">
              <el-icon><shop /></el-icon>
              <span class="hidden-sm-and-down">{{ $filters.capitalize($t('menu.shop')) }}</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="3" :title="$filters.capitalize($t('menu.provider'))">
            <el-link href="/provider" class="link-item"  :class="menuActive == 3 ? 'active' : ''">
              <el-icon><office-building /></el-icon>
              <span class="hidden-sm-and-down">{{ $filters.capitalize($t('menu.provider')) }}</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="4" :title="$filters.capitalize($t('menu.product'))">
            <el-link href="/product" class="link-item"  :class="menuActive == 4 ? 'active' : ''">
              <el-icon><box /></el-icon>
              <span class="hidden-sm-and-down">{{ $filters.capitalize($t('menu.product')) }}</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="5" :title="$filters.capitalize($t('menu.import-stock'))">
            <el-link href="/import-stock" class="link-item"  :class="menuActive == 5 ? 'active' : ''">
              <el-icon><document-add /></el-icon>
              <span class="hidden-sm-and-down">{{ $filters.capitalize($t('menu.import-stock')) }}</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="6" :title="$filters.capitalize($t('menu.export-stock'))">
            <el-link href="/export-stock" class="link-item"  :class="menuActive == 6 ? 'active' : ''">
              <el-icon><document-remove /></el-icon>
              <span class="hidden-sm-and-down">{{ $filters.capitalize($t('menu.export-stock')) }}</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="7" :title="$filters.capitalize($t('menu.order'))">
            <el-link href="/order" class="link-item"  :class="menuActive == 7 ? 'active' : ''">
              <el-icon><shopping-cart /></el-icon>
              <span class="hidden-sm-and-down">{{ $filters.capitalize($t('menu.order')) }}</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="8" :title="$filters.capitalize($t('menu.ship-order'))">
            <el-link href="/ship-order" class="link-item"  :class="menuActive == 8 ? 'active' : ''">
              <el-icon><van /></el-icon>
              <span class="hidden-sm-and-down">{{ $filters.capitalize($t('menu.ship-order')) }}</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="9" :title="$filters.capitalize($t('menu.cost-type'))">
            <el-link href="/cost-type" class="link-item"  :class="menuActive == 9 ? 'active' : ''">
              <el-icon><money /></el-icon>
              <span class="hidden-sm-and-down">{{ $filters.capitalize($t('menu.cost-type')) }}</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="10" :title="$filters.capitalize($t('menu.cost'))">
            <el-link href="/cost" class="link-item"  :class="menuActive == 10 ? 'active' : ''">
              <el-icon><coin /></el-icon>
              <span class="hidden-sm-and-down">{{ $filters.capitalize($t('menu.cost')) }}</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="11" :title="$filters.capitalize($t('menu.blog'))">
            <el-link href="/news" class="link-item"  :class="menuActive == 11 ? 'active' : ''">
              <el-icon><memo /></el-icon>
              <span class="hidden-sm-and-down">{{ $filters.capitalize($t('menu.blog')) }}</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="12" :title="$filters.capitalize($t('title.short-video'))">
            <el-link href="/short-video" class="link-item"  :class="menuActive == 12 ? 'active' : ''">
              <el-icon><video-camera /></el-icon>
              <span class="hidden-sm-and-down">{{ $filters.capitalize($t('title.short-video')) }}</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="13" :title="$filters.capitalize($t('menu.statistic'))">
            <el-link href="/statistic" class="link-item"  :class="menuActive == 13 ? 'active' : ''">
              <el-icon><trend-charts/></el-icon>
              <span class="hidden-sm-and-down">{{ $filters.capitalize($t('menu.statistic')) }}</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="14" :title="$filters.capitalize($t('menu.profile'))">
            <el-link href="/profile" class="link-item"  :class="menuActive == 14 ? 'active' : ''">
              <el-icon><user /></el-icon>
              <span class="hidden-sm-and-down">{{ $filters.capitalize($t('menu.profile')) }}</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="15" :title="$filters.capitalize($t('menu.customer'))">
            <el-link href="/customer" class="link-item"  :class="menuActive == 15 ? 'active' : ''">
              <el-icon><user /></el-icon>
              <span class="hidden-sm-and-down">{{ $filters.capitalize($t('menu.customer')) }}</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="16" :title="$filters.capitalize($t('menu.analytic-price'))">
            <el-link href="/search-engine" class="link-item"  :class="menuActive == 16 ? 'active' : ''">
              <el-icon><data-analysis /></el-icon>
              <span class="hidden-sm-and-down">{{ $filters.capitalize($t('menu.analytic-price')) }}</span>
            </el-link>
          </el-menu-item>
          <el-menu-item index="17" :title="$filters.capitalize($t('menu.logout'))">
            <el-link href="javascript:;" class="link-item"  :class="menuActive == 17 ? 'active' : ''" @click="onLogout">
              <el-icon><lock /></el-icon>
              <span class="hidden-sm-and-down">{{ $filters.capitalize($t('menu.logout')) }}</span>
            </el-link>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </template>
  
  <script setup>
  import {
    Menu as IconMenu,
    Lock,
    User,
    Box,
    ShoppingCart,
    Shop,
    OfficeBuilding,
    DocumentAdd,
    DocumentRemove,
    Memo,
    Money,
    Coin,
    TrendCharts,
    Van,
    DataAnalysis,
    VideoCamera
  } from '@element-plus/icons-vue'
  const handleOpen = (key , keyPath) => {
    // console.log(key, keyPath)
  }
  const handleClose = (key, keyPath) => {
    // console.log(key, keyPath)
  }
  
  </script>
  <script>
  export default {
    name: "LeftMenu",
    data(){
      return {
        menuActive: "1",
      }
    },
    created(){
      this.getRoute();
    },
    methods: {
      async onLogout(){
        this.$store.dispatch('user/logout');
        this.$router.push({ path: '/login' });
      },
      getRoute() {
        const routeArr = this.$route.path.split("/");
        if (routeArr[1] == 'shop') {
          this.menuActive = "2";
        } else if (routeArr[1] == 'provider') {
          this.menuActive = "3";
        } else if (routeArr[1] == 'product') {
          this.menuActive = "4";
        } else if (routeArr[1] == 'import-stock') {
          this.menuActive = "5";
        } else if (routeArr[1] == 'export-stock') {
          this.menuActive = "6";
        } else if (routeArr[1] == 'order') {
          this.menuActive = "7";
        } else if (routeArr[1] == 'ship-order') {
          this.menuActive = "8";
        } else if (routeArr[1] == 'cost-type') {
          this.menuActive = "9";
        } else if (routeArr[1] == 'cost') {
          this.menuActive = "10";
        } else if (routeArr[1] == 'news') {
          this.menuActive = "11";
        } else if (routeArr[1] == 'short-video') {
          this.menuActive = "12";
        } else if (routeArr[1] == 'statistic') {
          this.menuActive = "13";
        } else if (routeArr[1] == 'profile') {
          this.menuActive = "14";
        } else if (routeArr[1] == 'customer') {
          this.menuActive = "15";
        } else if (routeArr[1] == 'search-engine' || routeArr[1] == 'search-result') {
          this.menuActive = "16";
        } else if (routeArr[1] == 'logout') {
          this.menuActive = "17";
        }
      },
    },
    watch:{
      '$route'(to, from) {
        console.log(from);
        const routeArr = to.fullPath.split("/");
        if (routeArr[1] == 'shop') {
          this.menuActive = "2";
        } else if (routeArr[1] == 'provider') {
          this.menuActive = "3";
        } else if (routeArr[1] == 'product') {
          this.menuActive = "4";
        } else if (routeArr[1] == 'import-stock') {
          this.menuActive = "5";
        } else if (routeArr[1] == 'export-stock') {
          this.menuActive = "6";
        } else if (routeArr[1] == 'order') {
          this.menuActive = "7";
        } else if (routeArr[1] == 'ship-order') {
          this.menuActive = "8";
        } else if (routeArr[1] == 'cost-type') {
          this.menuActive = "9";
        } else if (routeArr[1] == 'cost') {
          this.menuActive = "10";
        } else if (routeArr[1] == 'news') {
          this.menuActive = "11";
        } else if (routeArr[1] == 'short-video') {
          this.menuActive = "12";
        } else if (routeArr[1] == 'statistic') {
          this.menuActive = "13";
        } else if (routeArr[1] == 'profile') {
          this.menuActive = "14";
        } else if (routeArr[1] == 'customer') {
          this.menuActive = "15";
        } else if (routeArr[1] == 'search-engine' || routeArr[1] == 'search-result') {
          this.menuActive = "16";
        } else if (routeArr[1] == 'logout') {
          this.menuActive = "17";
        }
      }
    }
  };
  </script>
  <style>
  .el-link {
    color: #fff !important;
    justify-content: left !important;
  }
  .el-link.active {
    color: #e6a23c !important;
  }
.link-item {
    background-color: inherit;
    color: inherit;
    width: 100%;
  }
</style>
  
import axios from 'axios'
// import { ArgonAlert } from '@/components/ArgonAlert.vue';
import store from '@/store'
import { getToken } from '@/utils/authentication'
import { ElMessage, ElMessageBox } from 'element-plus'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // timeout: 20000 // request timeout
  timeout: 120000
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token) {
        // config.headers['Authorization'] = `Bearer ${getToken()}`
        config.headers['X-CSRF-TOKEN'] = `${getToken()}`
    }
    config.headers['Accept'] = 'application/json'
    // config.headers['Content-Type'] = 'multipart/form-data'
    config.headers['Content-Type'] = 'multipart/form-data'
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    if (response.request.responseType === 'blob') {
      return response
    }
    if (response.status !== 200) {
      ElMessage({
        message: response.message.toString() || 'Error',
        type: 'error',
        duration: 5 * 1000
      });
      if (response.status === 401 || response.status === 402 || response.status === 403 || response.status === 503) {
        ElMessageBox.confirm(
          'You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout',
          'Danger',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'danger',
            draggable: true
          }
        ).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          });
          return Promise.resolve(response);
        }).catch(() => {
          console.log('no');
        });
      }
      // console.log(response);
      return Promise.resolve(response);
    } else {
        // console.log(response);
      return response;
    }
  },
  error => {
    ElMessage.error(error.response.message || error.message)
    return Promise.resolve(error)
  }
)

export default service
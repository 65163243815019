import request from '@/utils/request'
function list(query){
    return request({
        url: '/shop-category?'+query,
        method: 'get'
    });
}
function detail(id){
    return request({
        url: '/shop-category/'+id,
        method: 'get'
    });
}
export {list, detail};
<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('menu.shop'))">
                <el-input v-model="form.shop.name" placeholder="shop" readonly  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('order.customer'))">
                <el-input v-model="form.user.name" placeholder="user" readonly />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('order.payment-method'))">
                <el-select v-model="form.payment_method" readonly>
                <el-option
                    v-for="item in payment_methods"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('order.is-paid'))">
                <el-select v-model="form.payment_status" readonly>
                <el-option
                    v-for="item in payment_status"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('order.ship-method'))">
                <el-select v-model="form.ship_method" readonly>
                <el-option
                    v-for="item in ship_methods"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('order.receive-address'))">
                <el-input v-model="form.ship_address" placeholder="ship address" readonly />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('order.receive-phone'))">
                <el-input v-model="form.receive_phone" placeholder="receive phone" readonly />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.notes'))">
                <el-input v-model="form.notes" placeholder="notes" readonly />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('order.order-date'))">
                <el-date-picker v-model="form.order_date" type="datetime" format="YYYY-MM-DD HH:mm:ss" readonly  />
            </el-form-item>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24">
                <el-table :data="form.products" style="width: 100%">
                    <el-table-column label="#" width="50">
                        <template #default="scope">
                            {{ scope.$index }}
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('menu.product'))">
                        <template #default="scope">
                            <el-select v-model="scope.row.id" readonly>
                                <el-option
                                    v-for="item in products"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                />
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.quantity'))">
                        <template #default="scope">
                            <el-input-number v-model="scope.row.qty" size="small" step="1" min="1" readonly />
                        </template>
                    </el-table-column>
                    <el-table-column :label="$filters.capitalize($t('title.notes'))">
                        <template #default="scope">
                            <el-input v-model="scope.row.notes" size="small" readonly />
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('title.status'))">
                <el-select v-model="form.status" >
                <el-option
                    v-for="item in statusList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">{{ $filters.capitalize($t('action.save')) }}</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                <router-link :to="{name: 'OrderIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                </router-link>
            </el-col>
        </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
} from '@element-plus/icons-vue'
</script>
<script>
import { detail, edit, config } from '@/api/order';
import {list as listProducts} from '@/api/product';
import { ElMessage } from 'element-plus';
export default {
    name: "OrderEdit",
    data(){
        return {
            form: {
                shop_id: 0,
                products: [],
                notes: '',
                order_date: (new Date()),
                status: 1,
                user_id: 0,
                payment_method: 0,
                ship_method: 0,
                ship_address: '',
                receive_phone: '',
                payment_status: 0,
                shop: {},
                user: {}
            },
            statusList: [
            ],
            products: [],
            payment_methods: [],
            ship_methods: [],
            payment_status: []
        };
    },
    created(){
        this.getDetail();
        this.getConfig();
    },
    methods: {
        async getDetail() {
            let res = await detail(this.$route.params.id);
            if (res != null && res.status == 200 && res.data != null) {
                this.form = res.data;
                if (this.form.shop_id > 0) {
                    this.getProducts(this.form.shop_id);
                }
                if (res.data.order_details.length > 0) {
                    this.form.products = [];
                    for(let i = 0; i < res.data.order_details.length; i++){
                        this.form.products.push({
                            id: res.data.order_details[i].product_id,
                            qty: res.data.order_details[i].qty,
                            notes: res.data.order_details[i].notes
                        });
                    }
                }
                if (this.form.order_date != null) {
                    this.form.order_date = new Date(this.form.order_date*1000);
                }
            }
        },
        async getConfig() {
            let res = await config();
            if (res != null && res.status == 200 && res.data != null) {
                if (res.data.list_payment_methods != null) {
                    this.payment_methods = res.data.list_payment_methods;
                }
                if (res.data.list_ship_methods != null) {
                    this.ship_methods = res.data.list_ship_methods;
                }
                if (res.data.list_payment_status != null) {
                    this.payment_status = res.data.list_payment_status;
                }
                if (res.data.list_order_status != null) {
                    this.statusList = res.data.list_order_status;
                }
            }
        },
        async getProducts(shopId) {
            let res = await listProducts('page=1&pagesize=10000&shop_id='+shopId.toString());
            if (res != null && res.status == 200 && res.data != null) {
                this.products = res.data.data;
            }
        },
        async submitForm(){
            this.$store.state.loading = true;
            let res = await edit(this.$route.params.id, this.form.status);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    type: 'success',
                });
                this.$router.push({ path: '/order' });
            } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-failure')),
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.getDetail();
            this.getConfig();
        },
        removeProduct(index) {
            this.form.products.splice(index, 1);
        },
        addProduct(){
            this.form.products.push({
                id: 0,
                qty: 1,
                notes: ''
            });
        },
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>
<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" style="width: 100%;" label-width="auto" label-position="top">
        <el-text class="mx-1" type="danger">{{ $filters.capitalize($t('title.image-have-size')) + shop_image.width +"x"+shop_image.height+"px" }}</el-text>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFile"
                    :before-upload="beforeUpload"
                    accept="image/jpg, image/jpeg, image/png"
                    auto-upload="false"
                >

                <img v-if="form.feature_image" :src="form.feature_image" class="avatar" style="max-width: 480px;" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
        <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('shop.banner'))">
        </el-form-item>
        <el-text class="mx-1" type="danger">{{ $filters.capitalize($t('title.image-have-size')) + shop_banner_image.width +"x"+shop_banner_image.height+"px" }}</el-text>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    accept="image/jpg, image/jpeg, image/png"
                    :show-file-list="false"
                    :on-change="changeBanner"
                    :before-upload="beforeBanner"
                    auto-upload="false"
                >

                <img v-if="form.banner_image" :src="form.banner_image" class="avatar" style="max-width: 600px;"/>
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
        <el-form-item :label="$filters.capitalize($t('title.name'))">
            <el-input v-model="form.name" placeholder="Tên shop" style="max-width: 600px"  />
        </el-form-item>
        <el-form-item label="Slug" label-width="200px" style="max-width: 600px">
            <el-input v-model="form.slug" placeholder="slug" readonly />
        </el-form-item>
        <el-form-item :label="$filters.capitalize($t('title.owner'))" style="max-width: 600px">
            <el-input v-model="form.owner.email" placeholder="owner" readonly />
        </el-form-item>
        
            <el-form-item :label="$filters.capitalize($t('title.keywords'))" style="max-width: 600px">
                <el-input v-model="form.keywords" placeholder="Keywords"  />
            </el-form-item>
        
            <el-form-item :label="$filters.capitalize($t('title.meta-description'))" style="max-width: 600px">
                <el-input type="textarea" v-model="form.meta_description" placeholder=""  />
            </el-form-item>
            <el-form-item :label="$filters.capitalize($t('title.description'))" style="max-width: 600px">
            </el-form-item>
            <ckeditor :editor="editor" id="ck-description"  v-model="form.description" :config="editorConfig"></ckeditor>
            <el-form-item :label="$filters.capitalize($t('title.address'))" style="max-width: 600px">
                <el-input v-model="form.address" placeholder="address"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.phone'))">
                <el-input v-model="form.phone" placeholder="phone"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('shop.latitude'))">
                <el-input-number v-model="form.latitude"  />
            </el-form-item>
        
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.longitude'))">
                <el-input-number v-model="form.longitude" />
            </el-form-item>
        
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.website'))">
                <el-input v-model="form.website" placeholder="http://example.com"  />
            </el-form-item>
        <el-divider content-position="left">Liên kết mạng xã hội</el-divider>
        
            <el-form-item label="Facebook" style="max-width: 600px">
                <el-input v-model="form.facebook" placeholder="https://facebook.com" />
            </el-form-item>
        
            <el-form-item style="max-width: 600px" label="Twitter">
                <el-input v-model="form.twitter" placeholder="https://twitter.com"  />
            </el-form-item>
        
            <el-form-item style="max-width: 600px" label="Instagram">
                <el-input v-model="form.instagram" placeholder="https://instagram.com"  />
            </el-form-item>
            <el-form-item label="Tiktok" style="max-width: 600px">
                <el-input v-model="form.tiktok" placeholder="https://tiktok.com"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" label="Zalo">
                <el-input v-model="form.zalo" placeholder="zalo"  />
            </el-form-item>
        <el-divider content-position="left">{{ $filters.capitalize($t('shop.payment')) }}</el-divider>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('shop.bank-name'))">
                <el-input v-model="form.bank_name" placeholder="Bank name" />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('shop.account-name'))">
                <el-input v-model="form.bank_account" placeholder="Nguyen Van A" />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('shop.account-number'))">
                <el-input v-model="form.bank_number" placeholder="0123456"/>
            </el-form-item>
            <el-text class="mx-1" type="danger">{{ $filters.capitalize($t('title.image-have-size')) + shop_barcode_payment.width +"x"+shop_barcode_payment.height+"px" }}</el-text>
        <el-row type="flex" :gutter="1" style="width: 100%;">
            <el-col :span="24" type="flex" style="justify-content: center !important;">
                <el-upload
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :on-change="changeFileQrcode"
                    :before-upload="beforeUploadQrcode"
                    accept="image/jpg, image/jpeg, image/png"
                    auto-upload="false"
                >

                <img v-if="form.payment_qrcode" :src="form.payment_qrcode" class="avatar" style="max-width: 600px;" />
                <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-col>
        </el-row>
        <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('shop.template'))" >
            <el-select
                v-model="form.template_id"
                placeholder="Select"
                style="width: 240px"
                >
                <el-option
                    v-for="item in templates"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
        </el-form-item>
        <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.category'))" >
            <el-select
                v-model="form.shop_category_id"
                placeholder="Select"
                style="width: 240px"
                >
                <el-option
                    v-for="item in categories"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
                </el-select>
        </el-form-item>
        <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.province'))" >
            <el-select
                v-model="form.province_code"
                placeholder="Select"
                style="width: 240px"
                filterable
                >
                <el-option
                    v-for="item in provinces"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                />
                </el-select>
        </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.status'))">
                <el-select v-model="form.status" >
                <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="$filters.capitalize($t('title.'+item.label))"
                    :value="item.val"
                />
                </el-select>
          </el-form-item>
          <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('title.ship-province'))" style="max-width: 600px">
                <el-select v-model="form.ghn_province" @change="changeShipProvince">
                <el-option
                    v-for="(item, index) in ship_provinces"
                    :key="index"
                    :label="item.ProvinceName"
                    :value="item.ProvinceID"
                />
                </el-select>
          </el-form-item>
          <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('title.ship-district'))" style="max-width: 600px">
                <el-select v-model="form.ghn_district" @change="changeShipDistrict">
                <el-option
                    v-for="(item, index) in ship_districts"
                    :key="index"
                    :label="item.DistrictName"
                    :value="item.DistrictID"
                />
                </el-select>
          </el-form-item>
          <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('title.ship-ward'))" style="max-width: 600px">
                <el-select v-model="form.ghn_ward_code" >
                <el-option
                    v-for="(item, index) in ship_wards"
                    :key="index"
                    :label="item.WardName"
                    :value="item.WardCode"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">{{ $filters.capitalize($t('action.save')) }}</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                <router-link :to="{name: 'ShopIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                </router-link>
            </el-col>
          </el-row>
    </el-form>
    </el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh,
  Plus
} from '@element-plus/icons-vue'
</script>
<script>
import {list as listTemplate} from '@/api/shop_template';
import {list as listShopCategory} from '@/api/shop_category';
import {list as listProvince} from '@/api/province';
import {provinces as shipProvinces, districts as shipDistricts, wards as shipWards} from '@/api/ship';
import { edit, detail } from '@/api/shop';
import { ElMessage } from 'element-plus';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { shop_barcode_payment, shop_image, shop_banner_image } from '@/constants/configs';
export default {
    name: "ShopEdit",
    data(){
        return {
            form: {
                name: '',
                feature_image: '',
                slug: '',
                description: '',
                keywords: '',
                meta_description: '',
                image: null,
                status: 0,
                owner: {},
                payment_qrcode: '',
                address: '',
                phone: '',
                website: '',
                latitude: 0.0,
                longitude: 0.0,
                facebook: '',
                tiktok: '',
                twitter: '',
                instagram: '',
                zalo: '',
                bank_name: '',
                bank_account: '',
                bank_number: '',
                qrcode_file: null,
                banner_image: '',
                banner_file: null,
                template_id: null,
                shop_category_id: null,
                province_code: null,
                ghn_district: '',
                ghn_province: '',
                ghn_ward_code: ''
            },
            statusList: [
                {
                    val: 0,
                    label: 'no-active'
                },
                {
                    val: 1,
                    label: 'active'
                }
            ],
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
                'width': 'auto',
            },
            templates: [],
            categories: [],
            provinces: [],
            ship_provinces: [],
            ship_districts: [],
            ship_wards: []
        };
    },
    created(){
        this.getDetail();
        this.getShopTemplates();
        this.getCategories();
        this.getProvinces();
        this.getShipProvinces();
    },
    methods: {
        async getProvinces() {
            this.$store.state.loading = true;
            let res = await listProvince('page=1&pagesize=100');
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.provinces = res.data.data;
            }
        },
        async getShopTemplates() {
            this.$store.state.loading = true;
            let res = await listTemplate('page=1&pagesize=1000');
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.templates = res.data.data;
            }
        },
        async getCategories() {
            this.$store.state.loading = true;
            let res = await listShopCategory('page=1&pagesize=1000');
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.categories = res.data.data;
            }
        },
        async getShipProvinces(){
            this.$store.state.loading = true;
            let res = await shipProvinces();
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.ship_provinces = res.data;
            }
        },
        async getShipDistricts(provinceId){
            this.$store.state.loading = true;
            let res = await shipDistricts(provinceId);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.ship_districts = res.data;
            }
        },
        async getShipWards(districtId) {
            this.$store.state.loading = true;
            let res = await shipWards(districtId);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.ship_wards = res.data;
            }
        },
        async getDetail(){
            this.$store.state.loading = true;
            let res = await detail(this.$route.params.id);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.form = res.data;
                this.form.status = this.form.status ? 1 : 0;
                this.form.feature_image = this.form.feature_image != null && this.form.feature_image != '' ? process.env.VUE_APP_BASE_HOST + this.form.feature_image : null;
                this.form.payment_qrcode = this.form.payment_qrcode != null && this.form.payment_qrcode != '' ? process.env.VUE_APP_BASE_HOST + this.form.payment_qrcode : null;
                this.form.banner_image = this.form.banner_image != null && this.form.banner_image != '' ? process.env.VUE_APP_BASE_HOST + this.form.banner_image : null;
                this.form.latitude = Number(this.form.latitude);
                this.form.longitude = Number(this.form.longitude);
                this.form.ghn_province = this.form.ghn_province != null ?  Number(this.form.ghn_province) : '';
                if (this.form.ghn_province != null) {
                    this.getShipDistricts(this.form.ghn_province);
                    this.form.ghn_district = this.form.ghn_district != null ?  Number(this.form.ghn_district) : '';
                }
                if (this.form.ghn_district != null) {
                    this.getShipWards(this.form.ghn_district);
                    this.form.ghn_ward_code = this.form.ghn_ward_code != null ?  Number(this.form.ghn_ward_code) : '';
                }
            }
        },
        async submitForm(){
            if (this.form.name.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.name'),
                    type: 'error',
                });
                return;
            }
            if (this.form.keywords.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.keywords'),
                    type: 'error',
                });
                return;
            }
            if (this.form.description.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.description'),
                    type: 'error',
                });
                return;
            }
            if (this.form.meta_description.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.meta-description'),
                    type: 'error',
                });
                return;
            }
            if (this.form.ghn_district <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.ship-district'),
                    type: 'error',
                });
                return;
            }
            if (this.form.ghn_ward_code <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.ship-ward'),
                    type: 'error',
                });
                return;
            }
            this.$store.state.loading = true;
            let formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('description', this.form.description);
            formData.append('keywords', this.form.keywords);
            formData.append('meta_description', this.form.meta_description);
            if (this.form.image != null) {
                formData.append('feature_image', this.form.image);
            }
            formData.append('status', this.form.status);
            formData.append('address', this.form.address);
            formData.append('phone', this.form.phone);
            formData.append('website', this.form.website);
            formData.append('facebook', this.form.facebook);
            formData.append('tiktok', this.form.tiktok);
            formData.append('twitter', this.form.twitter);
            formData.append('instagram', this.form.instagram);
            formData.append('zalo', this.form.zalo);
            formData.append('latitude', this.form.latitude.toString());
            formData.append('longitude', this.form.longitude.toString());
            formData.append('bank_name', this.form.bank_name);
            formData.append('bank_account', this.form.bank_account);
            formData.append('bank_number', this.form.bank_number);
            formData.append('ghn_district', this.form.ghn_district);
            formData.append('ghn_province', this.form.ghn_province);
            formData.append('ghn_ward_code', this.form.ghn_province);
            if (this.form.qrcode_file != null) {
                formData.append('payment_qrcode', this.form.qrcode_file);
            }
            if (this.form.template_id != null) {
                formData.append('template_id', this.form.template_id);
            }
            if (this.form.province_code != null) {
                formData.append('province_code', this.form.province_code);
            }
            if (this.form.shop_category_id != null && this.form.shop_category_id > 0) {
                formData.append('shop_category_id', this.form.shop_category_id);
            }
            if (this.form.banner_file != null) {
                formData.append('banner_image', this.form.banner_file);
            }
            let res = await edit(this.$route.params.id, formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    type: 'success',
                });
                this.$router.push({ path: '/shop' });
            } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-failure')),
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.getDetail();
            this.form.image = null;
        },
        async changeFile(e){
            if(e.raw == null || e.raw.type.indexOf('image/') !== 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.wrong-format')),
                    type: 'error',
                });
                return;
            }
            const isLt2M = e.raw.size / 1024 / 1024 < shop_image.size;
            if (!isLt2M) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.file-less-or-equal'))+ shop_image.size + 'MB',
                    type: 'error',
                });
                return;
            }
            var tmppath = URL.createObjectURL(e.raw);
            const img = new Image();
            img.src = tmppath;
            await img.decode();
            // if (Math.abs(img.width - shop_image.width) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-width-equal'))+ shop_image.width + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            // if (Math.abs(img.width - shop_image.height) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-height-equal'))+ shop_image.height + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            this.form.image = e.raw;
            this.form.feature_image = tmppath;
        },
        beforeUpload(rawFile){
            console.log(rawFile);
            return false;
        },
        async changeFileQrcode(e){
            if(e.raw == null || e.raw.type.indexOf('image/') !== 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.wrong-format')),
                    type: 'error',
                });
                return;
            }
            const isLt2M = e.raw.size / 1024 / 1024 < shop_barcode_payment.size;
            if (!isLt2M) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.file-less-or-equal'))+ shop_barcode_payment.size + 'MB',
                    type: 'error',
                });
                return;
            }
            var tmppath = URL.createObjectURL(e.raw);
            const img = new Image();
            img.src = tmppath;
            await img.decode();
            // if (Math.abs(img.width - shop_barcode_payment.width) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-width-equal'))+ shop_barcode_payment.width + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            // if (Math.abs(img.width - shop_barcode_payment.height) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-height-equal'))+ shop_barcode_payment.height + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            this.form.qrcode_file = e.raw;
            this.form.payment_qrcode = tmppath;
        },
        beforeUploadQrcode(rawFile){
            console.log(rawFile);
            return false;
        },
        async changeBanner(e){
            if(e.raw == null || e.raw.type.indexOf('image/') !== 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.wrong-format')),
                    type: 'error',
                });
                return;
            }
            const isLt2M = e.raw.size / 1024 / 1024 < shop_banner_image.size;
            if (!isLt2M) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.file-less-or-equal'))+ shop_banner_image.size + 'MB',
                    type: 'error',
                });
                return;
            }
            var tmppath = URL.createObjectURL(e.raw);
            const img = new Image();
            img.src = tmppath;
            // await img.decode();
            // if (Math.abs(img.width - shop_banner_image.width) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-width-equal'))+ shop_banner_image.width + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            // if (Math.abs(img.height - shop_banner_image.height) > distance_tolerance) {
            //     ElMessage({
            //         message: this.$filters.capitalize(this.$t('title.image-height-equal'))+ shop_banner_image.height + 'px',
            //         type: 'error',
            //     });
            //     return;
            // }
            this.form.banner_file = e.raw;
            this.form.banner_image = tmppath;
        },
        beforeBanner(rawFile){
            if(!rawFile || rawFile.type.indexOf('image/') !== 0) return;
            return true;
        },
        changeShipProvince(value){
            this.getShipDistricts(value);
        },
        changeShipDistrict(value) {
            this.getShipWards(value);
        }
    }
}
</script>
<style>
#ck-description {
    width: auto !important;
}
</style>
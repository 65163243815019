<template>
    <el-container style="margin: 30px 20px;">
        <el-main>
    <el-form ref="form" :model="form" label-width="auto" label-position="top">
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('order.street-address'))" style="max-width: 600px;">
                <el-input v-model="form.street_address" placeholder="So 1, Hoang Ha" width="300px" @change="changeStreetAddress"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.ship-province'))">
                <el-select v-model="form.to_province_id" @change="changeProvince">
                <el-option
                    v-for="item in provinces"
                    :key="item.ProvinceID"
                    :label="item.ProvinceName"
                    :value="item.ProvinceID"
                />
                </el-select>
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.ship-district'))">
                <el-select v-model="form.to_district_id" @change="changeDistrict">
                <el-option
                    v-for="item in districts"
                    :key="item.DistrictID"
                    :label="item.DistrictName"
                    :value="item.DistrictID"
                />
                </el-select>
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.ship-ward'))">
                <el-select v-model="form.to_ward_code" @change="changeWard">
                <el-option
                    v-for="item in wards"
                    :key="item.WardCode"
                    :label="item.WardName"
                    :value="item.WardCode"
                />
                </el-select>
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.ship-service'))">
                <el-select v-model="form.service_id" @change="changeService">
                <el-option
                    v-for="item in services"
                    :key="item.service_id"
                    :label="item.short_name"
                    :value="item.service_id"
                />
                </el-select>
            </el-form-item>
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('order.receive-address'))" style="max-width: 600px;">
                <el-input v-model="form.to_address" placeholder="0" width="300px" readonly  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('title.length'))" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.length" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('title.width'))" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.width" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('title.height'))" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.height" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('title.weight'))" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.weight" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('ship-order.insurance-value'))" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.insurance_value" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('ship-order.insurance-fee'))" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.insurance_fee" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('ship-order.service-fee'))" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.service_fee" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('ship-order.pick-station-fee'))" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.pick_station_fee" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('ship-order.coupon-value'))" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.coupon_value" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item class="el-col" :span="24" :label="$filters.capitalize($t('ship-order.r2s-fee'))" style="max-width: 600px;">
                <el-input-number step="1" min="0" v-model="form.r2s_fee" placeholder="0" width="300px"  />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('ship-order.total-money'))" >
                <el-input v-model="form.cost" placeholder="cost" />
            </el-form-item>
            <el-form-item style="max-width: 600px" :label="$filters.capitalize($t('title.notes'))" >
                <el-input v-model="form.note" placeholder="notes" />
            </el-form-item>
            <el-form-item style="max-width: 600px" label="Status">
                <el-select v-model="form.status" >
                <el-option
                    v-for="item in statusList"
                    :key="item.id"
                    :label="$filters.capitalize($t('title.'+item.name))"
                    :value="item.id"
                />
                </el-select>
          </el-form-item>
        <el-row type="flex" :gutter="1" >
            <el-col :span="24" type="flex" justify="space-between">
                <el-button size="small" type="primary" @click="submitForm" :icon="Select">{{ $filters.capitalize($t('action.save')) }}</el-button>
                <el-button size="small" @click="freshForm" :icon="Refresh">{{ $filters.capitalize($t('action.refresh')) }}</el-button>
                <router-link :to="{name: 'ShipOrderIndex'}" style="margin-left: 10px;">
                    <el-button size="small" class="ms-10" type="success" :icon="Back">{{ $filters.capitalize($t('action.back')) }}</el-button>
                </router-link>
            </el-col>
        </el-row>
    </el-form>
</el-main>
</el-container>
</template>
<script setup>

import {
  Select,
  Back,
  Refresh
} from '@element-plus/icons-vue'
</script>
<script>
import { detail, edit } from '@/api/ship_order';
// import {detail as detailOrder} from '@/api/order';
import {provinces as shipProvinces, districts as shipDistricts, wards as shipWards, services as shipServices, price as shipPrice} from '@/api/ship';
import { ElMessage } from 'element-plus';
export default {
    name: "OrderAdd",
    data(){
        return {
            form: {
                order_id: 0,
                length: 0,
                width: 0,
                height: 0,
                weight: 0,
                insurance_value: 0,
                service_id: 0,
                to_province_id: 0,
                to_district_id: 0,
                to_ward_code: '',
                cost: 0,
                insurance_fee: 0,
                service_fee: 0,
                pick_station_fee: 0,
                coupon_value: 0,
                r2s_fee: 0,
                to_address: '',
                street_address: ''
            },
            statusList: [
                {
                id: 0,
                name: 'draft'
                },
                {
                id: 1,
                name: 'hand-over'
                },
                {
                id: 2,
                name: 'not-hand-over'
                },
                {
                id: 3,
                name: 'other'
                },
            ],
            orders: [],
            provinces: [],
            districts: [],
            wards: [],
            services: [],
            order: {},
            price: {}
        };
    },
    created(){
        this.getDetail();
        this.getProvinces();
    },
    methods: {
        async getDetail() {
            this.$store.state.loading = true;
            let res = await detail(this.$route.params.id);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.form = res.data;
                this.form.insurance_value = res.data.insurance_fee;
                // this.getOrderDetail(this.form.order_id);
                if (this.form.to_province_id) {
                    this.getDistricts(this.form.to_province_id);
                }
                if (this.form.to_district_id) {
                    this.getWards(this.form.to_district_id);
                }
                if (this.form.to_ward_code) {
                    this.getServices();
                }
            }
        },
        async getProvinces() {
            this.$store.state.loading = true;
            let res = await shipProvinces();
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.provinces = res.data;
            }
        },
        async getDistricts() {
            this.$store.state.loading = true;
            let res = await shipDistricts(this.form.to_province_id);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.districts = res.data;
            }
        },
        async getWards() {
            this.$store.state.loading = true;
            let res = await shipWards(this.form.to_district_id);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.wards = res.data;
            }
        },
        async getServices() {
            let data = new FormData();
            data.append('to_district', this.form.to_district_id);
            for(let i = 0; i < this.form.order.order_details.length; i++){
                data.append('product_ids[]', this.form.order.order_details[i].product_id);
            }
            this.$store.state.loading = true;
            let res = await shipServices(data);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.services = res.data;
            }
        },
        async getPrice() {
            let data = new FormData();
            data.append('to_district_id', Number(this.form.to_district_id));
            data.append('to_ward_code', this.form.to_ward_code.toString());
            data.append('service_id', Number(this.form.service_id));
            for(let i = 0; i < this.form.order.order_details.length; i++) {
                data.append('products['+i+'][id]', this.form.order.order_details[i].product_id);
                data.append('products['+i+'][qty]', this.form.order.order_details[i].qty);
            }
            this.$store.state.loading = true;
            let res = await shipPrice(data);
            this.$store.state.loading = false;
            if (res != null && res.status == 200 && res.data != null) {
                this.price = res.data;
            }
        },
        async submitForm(){
            if (this.form.order_id <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('menu.order'),
                    type: 'error',
                });
                return;
            }
            if (this.form.to_province_id <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.ship-province'),
                    type: 'error',
                });
                return;
            }
            if (this.form.to_district_id <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.ship-district'),
                    type: 'error',
                });
                return;
            }
            if (this.form.to_ward_code <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.ship-ward'),
                    type: 'error',
                });
                return;
            }
            if (this.form.service_id <= 0) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('title.ship-service'),
                    type: 'error',
                });
                return;
            }
            if (this.form.to_address.trim() == '') {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.please-input')) + " " + this.$t('order.receive-address'),
                    type: 'error',
                });
                return;
            }
            let formData = new FormData();
            formData.append('order_id', Number(this.form.order_id));
            formData.append('to_province_id', Number(this.form.to_province_id));
            formData.append('to_district_id', Number(this.form.to_district_id));
            formData.append('to_ward_code', Number(this.form.to_ward_code));
            formData.append('service_id', Number(this.form.service_id));
            formData.append('note', this.form.note.trim());
            formData.append('to_address', this.form.to_address.trim());
            formData.append('length', Number(this.form.length));
            formData.append('width', Number(this.form.width));
            formData.append('height', Number(this.form.height));
            formData.append('weight', Number(this.form.weight));
            formData.append('insurance_value', Number(this.form.insurance_value));
            formData.append('status', Number(this.form.status));
            formData.append('ship_org_type', 1);
            this.$store.state.loading = true;
            let res = await edit(this.form.id, formData);
            this.$store.state.loading = false;
            if (res != null && res.status == 200) {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-success')),
                    type: 'success',
                });
                this.$router.push({ path: '/ship-order' });
            } else {
                ElMessage({
                    message: this.$filters.capitalize(this.$t('title.save-failure')),
                    type: 'error',
                });
            }
        },
        async freshForm(){
            this.provinces = [];
            this.districts = [];
            this.order = {};
            this.wards = {};
            this.services = {};
            this.price = {};
            this.getDetail();
            this.getProvinces();
        },
        // async getOrderDetail(value) {
        //     let res = await detailOrder(value);
        //     if (res != null && res.status == 200 && res.data != null) {
        //         this.order = res.data;
        //     }
        // },
        changeOrder(value){
            this.order = {};
            this.getOrderDetail(value);
        },
        changeProvince(value) {
            console.log(value);
            this.getDistricts();
        },
        changeDistrict(value) {
            console.log(value);
            this.getWards();
        },
        changeWard(value) {
            if (value) {
                let str = this.form.street_address.trim();
                let foundWard = this.wards.filter(el => el.WardCode == value);
                if (foundWard) {
                    str += ", " + foundWard[0].WardName;
                }
                let foundDistrict = this.districts.filter(el => el.DistrictID == this.form.to_district_id);
                if (foundDistrict) {
                    str += ", " + foundDistrict[0].DistrictName;
                }
                let foundProvince = this.provinces.filter(el => el.ProvinceID == this.form.to_province_id);
                if (foundProvince) {
                    str += ", " + foundProvince[0].ProvinceName;
                }
                this.form.to_address = str;
            }
            this.getServices();
        },
        changeService(value) {
            console.log(value);
            this.getPrice();
        },
        changeStreetAddress(value){
            this.form.to_address = value.trim();
        }
    }
}
</script>
<style>
.form-add .el-input {
    width: 300px !important;
}
</style>